import React from 'react';
import PropTypes from 'prop-types';
import loadImage from 'blueimp-load-image';
import Paper from 'material-ui/Paper';
import TextField from 'material-ui/TextField';
import Checkbox from 'material-ui/Checkbox';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import ImageAddAPhoto from 'material-ui/svg-icons/image/add-a-photo';
import Subheader from 'material-ui/Subheader';
import { red500 } from 'material-ui/styles/colors';
import { Row, Col } from 'react-flexbox-grid';
import { UserPropType, CategoryPropType } from 'common/constants/PropTypes';

const styles = {
  root: {
    maxWidth: 550,
    margin: '0 auto',
    marginBottom: 30,
    overflowX: 'hidden',
  },
  heading: {
    marginBottom: 20,
    textAlign: 'center',
  },
  error: {
    color: red500,
    marginTop: 10,
    marginBottom: 10,
  },
  container: {
    padding: 10,
    marginTop: 10,
  },
  imageContainer: {
    width: 150,
    height: 150,
    border: 'dotted 2px',
    display: 'inline-block',
    marginRight: 20,
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  uploadButton: {
    verticalAlign: 'middle',
    display: 'inline-block',
    marginTop: -20,
  },
  uploadInput: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    opacity: 0,
  },
  audience: {
    marginTop: 20,
    marginBottom: 10,
  },
};

class EditUser extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { checkedCategories: {}, tags: [], imageData: null, profileAudience: null };
    this.handleChangeTagTextField = this.handleChangeTagTextField.bind(this);
    this.handleFileInput = this.handleFileInput.bind(this);
    this.handleAudienceCheckbox = this.handleAudienceCheckbox.bind(this);
  }

  componentWillMount() {
    const { user } = this.props;
    this.mapPropsTagsToState(user.tag_list);
    if (user.image && user.image.file_url) {
      this.setState({ imageData: user.image.file_url });
    }
    this.setState({ profileAudience: user.profile_audience || 'public' });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user.tag_list !== this.props.user.tag_list) {
      this.mapPropsTagsToState(nextProps.user.tag_list);
    }
  }

  mapPropsTagsToState(tagList) {
    const categories = this.context.categories.map(c => c.name);
    const checkedCategories = {};
    const tags = [];
    tagList.forEach((t) => {
      if (categories.includes(t)) {
        checkedCategories[t] = true;
      } else {
        tags.push(t);
      }
    });
    this.setState({ checkedCategories, tags });
  }

  handleAudienceCheckbox(event, isInputChecked) {
    this.setState({ profileAudience: isInputChecked ? 'members' : 'public' });
  }

  handleCategoryCheckbox(name, isInputChecked) {
    const checkedCategories = Object.assign(
      {},
      this.state.checkedCategories,
      { [name]: isInputChecked },
    );
    this.setState({ checkedCategories });
  }

  handleChangeTagTextField(event, value) {
    const newVal = value.replace(/\u{3000}/g, ' ') // 全角を半角に
      .replace(/\s+/g, ' ') // 連続する空白を一つに
      .replace(/[#,]/g, ''); // #と,は取り除く
    this.setState({ tags: newVal.split(' ') });
  }

  handleFileInput(event) {
    const input = event.target;
    const file = input.files[0];
    const self = this;

    loadImage.parseMetaData(file, (data) => {
      const options = {
        maxWidth: 150,
        maxHeight: 150,
        canvas: true,
      };
      if (data.exif) {
        options.orientation = data.exif.get('Orientation');
      }
      loadImage(file, (canvas) => {
        const imageData = canvas.toDataURL('image/jpeg');
        self.setState({ imageData });
      }, options);
    });
  }

  renderErrors() {
    const { errors } = this.props;
    if (!errors || errors.length === 0) {
      return null;
    }

    return (
      <div>
        {errors.map(e => <div style={styles.error}>{e}</div>)}
      </div>
    );
  }

  renderCategories() {
    const { categories } = this.context;
    const checkboxes = categories.map(c => (
      <Col xs={6} key={c.name}>
        <Checkbox
          label={c.name}
          onCheck={(event, isInputChecked) => {
            this.handleCategoryCheckbox(c.name, isInputChecked);
          }}
          checked={this.state.checkedCategories[c.name]}
        />
      </Col>
    ));
    return (
      <div>
        <Subheader>タグ</Subheader>
        <Row>
          {checkboxes}
        </Row>
        <TextField
          hintText="キーワードをスペースで区切って記入"
          floatingLabelText="その他のタグ（スペース区切り）"
          floatingLabelFixed
          fullWidth
          onChange={this.handleChangeTagTextField}
          value={this.state.tags.join(' ')}
        />
      </div>
    );
  }

  renderCategoriesHiddenForms() {
    const { checkedCategories, tags } = this.state;
    const categories = Object.keys(checkedCategories).reduce((p, c) => {
      if (checkedCategories[c]) {
        return p.concat(c);
      }
      return p;
    }, []);
    const tagList = [].concat(tags).concat(categories);

    return (
      <div>
        {tagList.map(t => <input key={t} type="hidden" name="user[tag_list][]" value={t} />)}
      </div>
    );
  }

  renderImage() {
    const img = this.state.imageData ?
      (<img src={this.state.imageData} style={styles.image} alt="プロフィール画像" />) :
      null;
    const style = this.state.imageData ?
      Object.assign({}, styles.imageContainer, { border: 'none' }) :
      styles.imageContainer;
    return (
      <div>
        <Subheader>画像</Subheader>
        <div>
          <div style={style}>
            {img}
          </div>
          <FlatButton
            label="画像を選択"
            style={styles.uploadButton}
            containerElement="label"
            icon={<ImageAddAPhoto />}
          >
            <input
              type="file"
              name="user[image]"
              accept="image/*"
              style={styles.uploadInput}
              onChange={this.handleFileInput}
            />
          </FlatButton>
        </div>
      </div>
    );
  }

  render() {
    const { user } = this.props;
    const { profileAudience } = this.state;
    const { authenticityToken } = this.context;

    const dummyStyle = {
      width: 2,
      height: 2,
      position: 'absolute',
      opacity: 0,
    };

    return (
      <div style={styles.root}>
        <h2 style={styles.heading}>
          {this.context.user.name}
          <br />
          プロフィール編集
        </h2>
        {this.renderErrors()}
        <Paper style={styles.container}>
          <form action="/users" method="post" encType="multipart/form-data">
            <input type="hidden" name="authenticity_token" value={authenticityToken} />
            <input type="hidden" name="_method" value="patch" />
            <input type="text" name="dummy_text" disabled style={dummyStyle} />
            <input type="password" name="dummy_password" disabled style={dummyStyle} />
            <TextField
              defaultValue={user.name}
              name="user[name]"
              fullWidth
              floatingLabelText="ニックネーム（日本語OK）"
              required
            />
            <TextField
              defaultValue={user.login}
              name="user[login]"
              fullWidth
              floatingLabelText="ユーザ名"
              hintText="半角英数字４文字以上で入力してください"
              required
            />
            <TextField
              defaultValue={user.email}
              type="email"
              name="user[email]"
              fullWidth
              floatingLabelText="メールアドレス（非公開）"
              required
            />
            <TextField
              name="user[birthday]"
              defaultValue={user.birthday}
              floatingLabelText="生年月日（非公開）"
              floatingLabelFixed
              type="date"
              fullWidth
            />
            <TextField
              defaultValue={user.profile}
              type="text"
              name="user[profile]"
              fullWidth
              multiLine
              rows={2}
              floatingLabelText="一言コメント"
            />
            {this.renderImage()}
            {this.renderCategories()}
            {this.renderCategoriesHiddenForms()}
            <Checkbox
              label="プロフィールの公開を会員限定にする"
              checked={profileAudience === 'members'}
              onCheck={this.handleAudienceCheckbox}
              style={styles.audience}
            />
            <input type="hidden" name="user[profile_audience]" value={profileAudience} />
            <TextField
              name="user[current_password]"
              type="password"
              fullWidth
              floatingLabelText="現在のパスワード"
              errorText="セキュリティのため更新時に現在のパスワード入れてください"
              required
            />
            <Row style={{ marginTop: 30, marginBottom: 20 }}>
              <Col xs={6}>
                <RaisedButton href={`/users/${user.login}`} label="キャンセル" fullWidth />
              </Col>
              <Col xs={6}>
                <RaisedButton type="submit" label="更新" primary fullWidth />
              </Col>
            </Row>
          </form>
        </Paper>
      </div>
    );
  }
}

EditUser.propTypes = {
  user: UserPropType.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
};

EditUser.defaultProps = {
  errors: [],
};

EditUser.contextTypes = {
  user: UserPropType.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(CategoryPropType).isRequired,
};

export default EditUser;
