import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import IconButton from 'material-ui/IconButton';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import EditorModeEdit from 'material-ui/svg-icons/editor/mode-edit';
import HardwareKeyboardArrowDown from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import CommunicationCallMissedOutgoing from 'material-ui/svg-icons/communication/call-missed-outgoing';
import { lightBlack, grey500 } from 'material-ui/styles/colors';
import { PostPropType, UserPropType } from 'common/constants/PropTypes';
import UserAvatar from '../utils/UserAvatar';
import displayDateTime from '../../utils/displayDateTime';
import theme from '../../constants/theme';
import scrollTo from '../../utils/scrollTo';

const styles = {
  container: {
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  avatar: {
    width: 40,
    marginRight: 8,
  },
  text: {
    flex: 1,
    wordBreak: 'break-word',
  },
  button: {
    width: 40,
    marginRight: 8,
  },
  username: {
    textDecoration: 'none',
    fontWeight: 'bold',
    marginRight: 5,
  },
  userlogin: {
    fontWeight: 'normal',
    color: grey500,
    marginLeft: 5,
  },
  footer: {
    color: lightBlack,
    position: 'relative',
    paddingRight: 18,
  },
  likeComment: {
    cursor: 'pointer',
  },
  editIconButtonStyle: {
    position: 'absolute',
    top: -8,
    right: -8,
    margin: 'auto',
    width: 36,
    height: 36,
    padding: 8,
  },
  editIconStyle: {
    width: 18,
    height: 18,
  },
  dialogStyle: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
};

const renderLikeCount = (comment) => {
  const count = Object.keys(comment.likes.users).length;
  if (count === 0) {
    return null;
  }
  return (
    <span>
      <span>・</span>
      <span style={{ color: theme.palette.primary1Color }}>
        <i className="fa fa-heart" />
        <span style={{ marginLeft: 2 }}>{count}</span>
      </span>
    </span>
  );
};

/* eslint-disable react/no-danger */
const renderCommentContent = content => (
  <div
    dangerouslySetInnerHTML={{
      __html: content.replace(/[@＠]([a-zA-Z0-9_]{4,12})/g, (m, l) => `<a href="/users/${l}">${m}</a>`)
        .replace(/\[\[(([^[\]]+)>)?([^[\]]+)\]\]/g, (m, p1, p2, p3) => {
          const text = p2 || p3;
          if (p3.startsWith('http') && !p3.startsWith(location.origin)) {
            return `<a href="${p3}" target="_blank">${text}` +
              '<i class="fa fa-external-link" style="font-size:12px;margin-left:1px;"></i>' +
              '</a>';
          }
          return `<a href="${p3}">${text}</a>`;
        })
        .replace(/\n/g, '<br />'),
    }}
  />
);
/* eslint-enable */

class CommentList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      comment: '',
      editPopoverOpen: false,
      editButtonEl: null,
      editComment: null,
      editingComment: null,
      destroyCommentDialog: false,
      reportCommentDialog: false,
      blockUserDialog: false,
      containerWidth: 0,
    };
    this.handlePostComment = this.handlePostComment.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleEditingCommentChange = this.handleEditingCommentChange.bind(this);
    this.handleFocusTextField = this.handleFocusTextField.bind(this);
    this.handleBlurTextField = this.handleBlurTextField.bind(this);
    this.handleTouchTapLikeComment = this.handleTouchTapLikeComment.bind(this);
    this.handleEditPopoverRequestClose = this.handleEditPopoverRequestClose.bind(this);
    this.handleDeleteComment = this.handleDeleteComment.bind(this);
    this.handleUpdateComment = this.handleUpdateComment.bind(this);
    this.handleReportComment = this.handleReportComment.bind(this);
    this.handleBlockUser = this.handleBlockUser.bind(this);
  }

  componentDidMount() {
    if (location.hash === '#comments') {
      scrollTo(`post${this.props.post.id}CommentInput`);
      if ('replaceState' in history) {
        // URLからハッシュをなくす
        history.replaceState('', document.title, location.pathname + location.search);
      }
    }
  }

  handleFocusTextField() {
    this.props.handleCommentFocus(true);
  }

  handleBlurTextField() {
    const fn = () => { this.props.handleCommentFocus(false); };
    setTimeout(fn, 200);
  }

  handlePostComment() {
    const { post, submitComment } = this.props;
    const { user } = this.context;
    const { comment } = this.state;
    if (comment && comment.length > 0) {
      submitComment(post, user, comment);
      this.setState({ comment: '' });
    }
  }

  handleCommentChange(event, comment) {
    this.setState({ comment });
  }

  handleEditingCommentChange(event, content) {
    const { editingComment } = this.state;
    this.setState({ editingComment: Object.assign({}, editingComment, { content }) });
  }

  handleTouchTapLikeComment(comment) {
    const { user } = this.context;
    const { likeComment, unlikeComment, showDialogMessage } = this.props;
    if (!user) {
      if (showDialogMessage) {
        return () => { showDialogMessage('いいね！をするにはログインしてください。'); };
      }
      return null;
    }

    const liked = this.likedComment(comment);
    if (liked) {
      return () => { unlikeComment(comment, user); };
    }

    return () => { likeComment(comment, user); };
  }

  handleTouchTapReplyComment(comment) {
    const { user } = this.context;
    const { showDialogMessage } = this.props;
    if (!user) {
      if (showDialogMessage) {
        showDialogMessage('返信するにはログインしてください。');
      }
      return;
    }
    const replyComment = `@${comment.user.login}\n`;
    this.setState(
      { comment: replyComment },
      () => setTimeout(() => {
        scrollTo(`post${this.props.post.id}CommentInput`);
        this.commentTextField.focus();
      }, 50),
    );
  }

  handleTouchEditButton(event, comment) {
    event.preventDefault();
    this.setState({
      editPopoverOpen: true,
      editButtonEl: event.currentTarget,
      editComment: comment,
    });
  }

  handleEditPopoverRequestClose() {
    this.setState({ editPopoverOpen: false });
  }

  handleDeleteComment() {
    this.setState({ destroyCommentDialog: false });
    const { post, destroyComment } = this.props;
    const { user } = this.context;
    const { editComment } = this.state;
    if (editComment && (editComment.user.id === user.id || post.user.id === user.id)) {
      destroyComment(post, user, editComment);
    }
  }

  handleReportComment() {
    this.setState({ reportCommentDialog: false });
    const { createReport } = this.props;
    const { user } = this.context;
    const { editComment } = this.state;
    createReport('Comment', editComment.id, user);
  }

  handleBlockUser() {
    this.setState({ blockUserDialog: false });
    const { blockUser } = this.props;
    const { user } = this.context;
    const { editComment } = this.state;
    blockUser(editComment.user.id, user);
  }

  handleUpdateComment() {
    const { post, updateComment } = this.props;
    const { user } = this.context;
    const { editingComment } = this.state;
    if (editingComment && (editingComment.user.id === user.id)) {
      updateComment(post, user, editingComment);
    }
    this.setState({ editingComment: null });
  }

  likedComment(comment) {
    const { user } = this.context;
    return !!user && Object.keys(comment.likes.users).includes(`${user.id}`);
  }

  renderComments() {
    let style = styles.text;
    if (this.state.containerWidth > 0) {
      const width =
        this.state.containerWidth - styles.avatar.width - styles.avatar.marginRight;
      style = { width, wordBreak: 'break-word' };
    }
    const { comments } = this.props.post;
    const commentNodes = comments.map(comment => (
      <div key={comment.id} style={styles.container}>
        <div style={styles.avatar}>
          <a href={`/users/${comment.user.login}`}>
            <UserAvatar user={comment.user} size={styles.avatar.width} />
          </a>
        </div>
        <div style={style}>
          <div>
            <a href={`/users/${comment.user.login}`} style={styles.username}>
              <span>{comment.user.name}</span>
              <span style={styles.userlogin}>@{comment.user.login}</span>
            </a>
            {renderCommentContent(comment.content)}
          </div>
          <div style={styles.footer}>
            <span>{displayDateTime(comment.created_at)}</span>
            <span>・</span>
            <span // eslint-disable-line jsx-a11y/no-static-element-interactions
              className="hover-underline"
              style={styles.likeComment}
              onClick={this.handleTouchTapLikeComment(comment)}
            >
              いいね！{this.likedComment(comment) ? 'を取消' : null}
            </span>
            {renderLikeCount(comment)}
            <span>・</span>
            <span // eslint-disable-line jsx-a11y/no-static-element-interactions
              className="hover-underline"
              style={styles.likeComment}
              onClick={() => { this.handleTouchTapReplyComment(comment); }}
            >
              返信
            </span>
            {this.renderEditButton(comment)}
          </div>
        </div>
      </div>
    ));

    return (
      <div>
        {commentNodes}
      </div>
    );
  }

  renderEditButton(comment) {
    const { user } = this.context;
    if (!user) {
      return null;
    }

    if (user.id !== comment.user.id) {
      return (
        <IconButton
          style={styles.editIconButtonStyle}
          iconStyle={styles.editIconStyle}
          onClick={(e) => { this.handleTouchEditButton(e, comment); }}
        >
          <HardwareKeyboardArrowDown color={lightBlack} />
        </IconButton>
      );
    }

    return (
      <IconButton
        style={styles.editIconButtonStyle}
        iconStyle={styles.editIconStyle}
        onClick={(e) => { this.handleTouchEditButton(e, comment); }}
      >
        <EditorModeEdit color={lightBlack} />
      </IconButton>
    );
  }

  renderPopover() {
    const { user } = this.context;
    const comment = this.state.editComment;
    if (!user || !comment) {
      return null;
    }

    if (user.id !== comment.user.id) {
      return this.renderPopoverOthersComment();
    }

    return this.renderPopoverMyComment();
  }

  renderPopoverMyComment() {
    return (
      <div>
        <Popover
          open={this.state.editPopoverOpen}
          anchorEl={this.state.editButtonEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          onRequestClose={this.handleEditPopoverRequestClose}
        >
          <Menu>
            <MenuItem
              primaryText="編集する..."
              onClick={() => {
                this.handleEditPopoverRequestClose();
                this.setState({ editingComment: this.state.editComment });
              }}
            />
            <MenuItem
              primaryText="削除する..."
              onClick={() => {
                this.handleEditPopoverRequestClose();
                this.setState({ destroyCommentDialog: true });
              }}
            />
          </Menu>
        </Popover>
        <Dialog
          actions={[
            <FlatButton
              label="削除"
              onClick={this.handleDeleteComment}
            />,
            <FlatButton
              label="キャンセル"
              keyboardFocused
              primary
              onClick={() => { this.setState({ destroyCommentDialog: false }); }}
            />,
          ]}
          open={this.state.destroyCommentDialog}
          onRequestClose={() => { this.setState({ destroyCommentDialog: false }); }}
        >
          本当に削除しますか？この操作は取り消せません。
        </Dialog>
      </div>
    );
  }

  renderPopoverOthersComment() {
    let destroyMenu = null;
    if (this.props.post.user.id === this.context.user.id) {
      destroyMenu = (
        <MenuItem
          primaryText="削除する..."
          onClick={() => {
            this.handleEditPopoverRequestClose();
            this.setState({ destroyCommentDialog: true });
          }}
        />
      );
    }

    return (
      <div>
        <Popover
          open={this.state.editPopoverOpen}
          anchorEl={this.state.editButtonEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          onRequestClose={this.handleEditPopoverRequestClose}
        >
          <Menu>
            <MenuItem
              primaryText="不適切なコメントとして通報する..."
              onClick={() => {
                this.handleEditPopoverRequestClose();
                this.setState({ reportCommentDialog: true });
              }}
            />
            <MenuItem
              primaryText="このユーザをブロックする..."
              onClick={() => {
                this.handleEditPopoverRequestClose();
                this.setState({ blockUserDialog: true });
              }}
            />
            {destroyMenu}
          </Menu>
        </Popover>
        <Dialog
          actions={[
            <FlatButton
              label="キャンセル"
              onClick={() => { this.setState({ reportCommentDialog: false }); }}
            />,
            <FlatButton
              label="通報"
              primary
              onClick={this.handleReportComment}
            />,
          ]}
          open={this.state.reportCommentDialog}
          onRequestClose={() => { this.setState({ reportCommentDialog: false }); }}
        >
          不適切なコメントとして通報しますか？
        </Dialog>
        <Dialog
          actions={[
            <FlatButton
              label="キャンセル"
              onClick={() => { this.setState({ blockUserDialog: false }); }}
            />,
            <FlatButton
              label="ブロック"
              primary
              onClick={this.handleBlockUser}
            />,
          ]}
          open={this.state.blockUserDialog}
          onRequestClose={() => { this.setState({ blockUserDialog: false }); }}
          title={`${this.state.editComment.user.name}をブロックしますか？`}
        >
          ブロックしたことは相手には知られません。
        </Dialog>
        <Dialog
          actions={[
            <FlatButton
              label="削除"
              onClick={this.handleDeleteComment}
            />,
            <FlatButton
              label="キャンセル"
              keyboardFocused
              primary
              onClick={() => { this.setState({ destroyCommentDialog: false }); }}
            />,
          ]}
          open={this.state.destroyCommentDialog}
          onRequestClose={() => { this.setState({ destroyCommentDialog: false }); }}
        >
          本当に削除しますか？この操作は取り消せません。
        </Dialog>
      </div>
    );
  }

  renderInput() {
    const { user } = this.context;
    if (!user) {
      return null;
    }
    return (
      <div id={`post${this.props.post.id}CommentInput`} style={styles.container}>
        <div style={styles.avatar}>
          <UserAvatar user={user} size={styles.avatar.width} />
        </div>
        <div style={styles.text}>
          <TextField
            hintText={this.state.comment ? null : 'コメントする…'} // window resize時にhintTextでサイズを決定してしまう不具合があるため
            fullWidth
            multiLine
            value={this.state.comment}
            onChange={this.handleCommentChange}
            ref={(textField) => { this.commentTextField = textField; }}
            name="post[comments][]"
            onFocus={this.handleFocusTextField}
            onBlur={this.handleBlurTextField}
          />
        </div>
        <div style={styles.button}>
          <IconButton
            onClick={this.handlePostComment}
            disabled={!this.state.comment || this.state.comment === 0}
          >
            <CommunicationCallMissedOutgoing />
          </IconButton>
        </div>
      </div>
    );
  }

  renderEdtingCommnet() {
    return (
      <Dialog
        actions={[
          <FlatButton
            label="キャンセル"
            onClick={() => { this.setState({ editingComment: null }); }}
          />,
          <FlatButton
            label="更新"
            keyboardFocused
            primary
            onClick={this.handleUpdateComment}
          />,
        ]}
        open={!!this.state.editingComment}
        onRequestClose={() => { this.setState({ editingComment: null }); }}
        autoDetectWindowHeight
        autoScrollBodyContent
        repositionOnUpdate
        contentStyle={styles.dialogStyle}
      >
        <TextField
          multiLine
          rows={4}
          fullWidth
          underlineShow={false}
          hintStyle={{ bottom: null, top: 12 }}
          name="post[content]"
          onChange={this.handleEditingCommentChange}
          value={this.state.editingComment && this.state.editingComment.content}
        />
      </Dialog>
    );
  }

  render() {
    return (
      <div
        ref={(c) => {
          if (!c || this.state.containerWidth === c.clientWidth) {
            return;
          }
          this.setState({ containerWidth: c.clientWidth });
        }}
      >
        {this.renderComments()}
        {this.renderInput()}
        {this.renderPopover()}
        {this.renderEdtingCommnet()}
      </div>
    );
  }
}

CommentList.propTypes = {
  post: PostPropType.isRequired,
  submitComment: PropTypes.func.isRequired,
  destroyComment: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  handleCommentFocus: PropTypes.func.isRequired,
  likeComment: PropTypes.func.isRequired,
  unlikeComment: PropTypes.func.isRequired,
  showDialogMessage: PropTypes.func,
  createReport: PropTypes.func.isRequired,
  blockUser: PropTypes.func.isRequired,
};

CommentList.defaultProps = {
  showDialogMessage: null,
};

CommentList.contextTypes = {
  user: UserPropType,
};

export default CommentList;
