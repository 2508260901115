import React from 'react';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';
import TextField from 'material-ui/TextField';
import Checkbox from 'material-ui/Checkbox';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import { red500 } from 'material-ui/styles/colors';
import marked from 'marked';
import TermsText from '../../../files/Terms.md';
import '../../../sass/markdown.scss';

const styles = {
  container: {
    padding: 10,
  },
  additional: {
    marginTop: 10,
    textAlign: 'center',
  },
  terms: {
    marginTop: 10,
  },
  termsDialogLink: {
    textDecoration: 'underline',
    color: 'inherit',
    cursor: 'pointer',
  },
  termsDialogStyle: {
    width: '100%',
    maxWidth: 780,
  },
  button: {
    marginTop: 30,
    marginBottom: 10,
  },
  error: {
    paddingBottom: 10,
    color: red500,
  },
  dummyStyle: {
    width: 2,
    height: 2,
    position: 'absolute',
    opacity: 0,
  },
};

function renderErrors(errors) {
  if (!errors) {
    return null;
  }

  return (
    <div>
      {errors.map(e => (
        <div key={e} style={styles.error}>{e}</div>
      ))}
    </div>
  );
}

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = { termsDialogOpen: false };
    this.handleTermsDialogClose = this.handleTermsDialogClose.bind(this);
  }

  handleTermsDialogClose() {
    this.setState({ termsDialogOpen: false });
  }

  render() {
    const { authenticityToken, errors, email, name, login } = this.props;

    return (
      <div>
        {renderErrors(errors)}
        <Paper style={styles.container}>
          <h2>無料会員登録</h2>
          <form action="/users" method="post">
            <input type="hidden" name="authenticity_token" value={authenticityToken} />
            <input type="text" name="dummy_text" disabled style={styles.dummyStyle} />
            <input type="password" name="dummy_password" disabled style={styles.dummyStyle} />
            <TextField
              hintText="メールアドレスを入力してください"
              floatingLabelText="メールアドレス（非公開）"
              name="user[email]"
              type="email"
              defaultValue={email}
              fullWidth
              required
            />
            <TextField
              hintText="半角英数字４文字以上で入力してください"
              floatingLabelText="ユーザ名（半角英数字４文字以上）"
              name="user[login]"
              type="text"
              defaultValue={login}
              fullWidth
              required
            />
            <TextField
              hintText="ニックネームを入力してください（日本語OK）"
              floatingLabelText="ニックネーム（日本語OK）"
              name="user[name]"
              type="text"
              defaultValue={name}
              fullWidth
              required
            />
            <TextField
              hintText="パスワードを入力してください"
              floatingLabelText="パスワード"
              name="user[password]"
              type="password"
              fullWidth
              required
            />
            <TextField
              hintText="パスワード（確認）を入力してください"
              floatingLabelText="パスワード（確認）"
              name="user[password_confirmation]"
              type="password"
              fullWidth
              required
            />
            <TextField
              name="user[birthday]"
              defaultValue={this.props.birthday}
              floatingLabelText="生年月日（非公開）"
              floatingLabelFixed
              type="date"
              fullWidth
              required
            />
            <div style={styles.terms}>
              <div>
                会員登録をされる前に、
                <span // eslint-disable-line jsx-a11y/no-static-element-interactions
                  style={styles.termsDialogLink}
                  onClick={() => { this.setState({ termsDialogOpen: true }); }}
                >
                  利用規約
                </span>
                をよくお読みください。
              </div>
              <Checkbox
                label="利用規約に同意する"
                defaultChecked
                required
              />
            </div>
            <RaisedButton
              style={styles.button}
              label="会員登録"
              type="submit"
              primary
              fullWidth
            />
          </form>
        </Paper>
        <div style={styles.additional}><a href="/users/sign_in">既にアカウントをお持ちの方はこちら</a></div>
        <Dialog
          actions={[
            <FlatButton
              label="閉じる"
              primary
              onClick={this.handleTermsDialogClose}
            />,
          ]}
          modal={false}
          open={this.state.termsDialogOpen}
          onRequestClose={this.handleTermsDialogClose}
          autoScrollBodyContent
          contentStyle={styles.termsDialogStyle}
        >
          <div
            className="markdown-body"
            /* eslint-disable react/no-danger */
            dangerouslySetInnerHTML={{ __html: marked(TermsText) }}
            /* eslint-enable */
          />
        </Dialog>
      </div>
    );
  }
}

SignUp.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  email: PropTypes.string,
  name: PropTypes.string,
  login: PropTypes.string,
  birthday: PropTypes.string,
};

SignUp.defaultProps = {
  errors: [],
  email: null,
  name: null,
  login: null,
  birthday: null,
};

export default SignUp;
