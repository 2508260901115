import { CALL_API } from 'redux-api-middleware';
import { createAction } from 'redux-actions';
import update from 'immutability-helper';
import { ActionTypes } from '../constants';
import createHeaders from '../utils/createHeaders';

const credentials = 'same-origin';
const headers = createHeaders();

export const fetchPosts = (page = 1, paginationKey, searchParam = null) => ({
  [CALL_API]: {
    endpoint: `/api/posts.json?page=${page}&${searchParam || ''}`,
    method: 'GET',
    types: Object.values(ActionTypes.FETCH_POSTS).map(type => ({
      type,
      meta: { paginationKey },
    })),
    headers,
    credentials,
  },
});

export const submitPost = (post, user, multipart = false) => {
  const newPost = update(post, { $merge: { device_info: global.navigator.userAgent } });
  const body = multipart ? new FormData() : JSON.stringify(newPost);
  if (multipart) {
    Object.keys(newPost).forEach((k) => {
      if (newPost[k] instanceof Array) {
        newPost[k].forEach((d) => {
          body.append(`post[${k}][]`, d);
        });
      } else {
        body.append(`post[${k}]`, newPost[k]);
      }
    });
  }

  return ({
    [CALL_API]: {
      endpoint: '/api/posts.json',
      method: 'POST',
      headers: createHeaders(user, true),
      body,
      types: Object.values(ActionTypes.SUBMIT_POST).map(type => ({
        type,
        meta: { postType: post.type },
      })),
      credentials,
    },
  });
};

export const updatePost = (post, user, multipart = false, deleteImageIds = []) => {
  const body = multipart ? new FormData() : JSON.stringify({
    post: {
      content: post.content,
      audience: post.audience,
      type: post.type,
    },
  });
  if (multipart) {
    Object.keys(post).forEach((k) => {
      if (post[k] instanceof Array) {
        post[k].forEach((d) => {
          body.append(`post[${k}][]`, d);
        });
      } else {
        body.append(`post[${k}]`, post[k]);
      }
    });
    deleteImageIds.forEach(id => body.append('delete_image_ids[]', id));
  }

  return ({
    [CALL_API]: {
      endpoint: `/api/posts/${post.id}.json`,
      method: 'PATCH',
      headers: createHeaders(user, multipart),
      body,
      types: Object.values(ActionTypes.UPDATE_POST).map(type => ({
        type,
        meta: { postType: post.type },
      })),
      credentials,
    },
  });
};

export const destroyPost = (post, user) => ({
  [CALL_API]: {
    endpoint: `/api/posts/${post.id}.json`,
    method: 'DELETE',
    headers: createHeaders(user),
    types: Object.values(ActionTypes.DESTROY_POST).map(type => ({
      type,
      meta: { id: post.id },
    })),
    credentials,
  },
});

export const submitComment = (post, user, comment) => ({
  [CALL_API]: {
    endpoint: `/api/posts/${post.id}/comments`,
    method: 'POST',
    headers: createHeaders(user),
    body: JSON.stringify({ content: comment }),
    types: Object.values(ActionTypes.SUBMIT_COMMENT),
    credentials,
  },
});

export const destroyComment = (post, user, comment) => ({
  [CALL_API]: {
    endpoint: `/api/posts/${post.id}/comments/${comment.id}`,
    method: 'DELETE',
    headers: createHeaders(user),
    types: Object.values(ActionTypes.DESTROY_COMMENT),
    credentials,
  },
});

export const updateComment = (post, user, comment) => ({
  [CALL_API]: {
    endpoint: `/api/posts/${post.id}/comments/${comment.id}`,
    method: 'PATCH',
    headers: createHeaders(user),
    body: JSON.stringify({ content: comment.content }),
    types: Object.values(ActionTypes.UPDATE_COMMENT),
    credentials,
  },
});

const toggleLikePost = (post, user, isLike = true, kind = 'like') => {
  const endpoint = isLike ? 'like' : 'unlike';
  const types = isLike ? ActionTypes.LIKE_POST : ActionTypes.UNLIKE_POST;
  return ({
    [CALL_API]: {
      endpoint: `/api/posts/${post.id}/${endpoint}`,
      method: 'PUT',
      headers: createHeaders(user),
      body: JSON.stringify({ kind }),
      types: Object.values(types).map(type => ({
        type,
        meta: { postId: post.id },
      })),
      credentials,
    },
  });
};

export const likePost = (post, user, kind = 'like') => toggleLikePost(post, user, true, kind);
export const unlikePost = (post, user) => toggleLikePost(post, user, false, null);

const toggleLikeComment = (comment, user, isLike = true) => {
  const endpoint = isLike ? 'like' : 'unlike';
  const types = isLike ? ActionTypes.LIKE_COMMENT : ActionTypes.UNLIKE_COMMENT;
  return ({
    [CALL_API]: {
      endpoint: `/api/comments/${comment.id}/${endpoint}`,
      method: 'PUT',
      headers: createHeaders(user),
      types: Object.values(types).map(type => ({
        type,
        meta: { commentId: comment.id },
      })),
      credentials,
    },
  });
};

export const likeComment = (comment, user) => toggleLikeComment(comment, user, true);
export const unlikeComment = (comment, user) => toggleLikeComment(comment, user, false);

const toggleFavoritePost = (post, user, isFavorite = true) => {
  const endpoint = isFavorite ? 'favorite' : 'unfavorite';
  const types = isFavorite ? ActionTypes.FAVORITE_POST : ActionTypes.UNFAVORITE_POST;
  return ({
    [CALL_API]: {
      endpoint: `/api/posts/${post.id}/${endpoint}`,
      method: 'PUT',
      headers: createHeaders(user),
      types: Object.values(types).map(type => ({
        type,
        meta: { postId: post.id },
      })),
      credentials,
    },
  });
};

export const favoritePost = (post, user) => toggleFavoritePost(post, user, true);

export const unfavoritePost = (post, user) => toggleFavoritePost(post, user, false);

export const fetchArticles = (page = 1) => ({
  [CALL_API]: {
    endpoint: `/api/articles.json?page=${page}`,
    method: 'GET',
    types: Object.values(ActionTypes.FETCH_ARTICLES).map(type => ({
      type,
      meta: { paginationKey: 'ARTICLES' },
    })),
    headers,
    credentials,
  },
});

export const createReport = (reportableType, reportableId, user) => {
  const body = JSON.stringify({
    report: {
      reportable_type: reportableType,
      reportable_id: reportableId,
    },
  });
  return ({
    [CALL_API]: {
      endpoint: '/api/reports.json',
      method: 'POST',
      headers: createHeaders(user),
      body,
      types: Object.values(ActionTypes.CREATE_REPORT),
      credentials,
    },
  });
};

export const blockUser = (blockedUserId, user) => ({
  [CALL_API]: {
    endpoint: `/api/users/${blockedUserId}/block_users.json`,
    method: 'POST',
    headers: createHeaders(user),
    types: Object.values(ActionTypes.BLOCK_USER),
    credentials,
  },
});

export const addMessage = createAction(ActionTypes.ADD_MESSAGE, message => message);
export const removeMessage = createAction(ActionTypes.REMOVE_MESSAGE, message => message);
