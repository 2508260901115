import React from 'react';
import PropTypes from 'prop-types';
import Popover from 'material-ui/Popover';
import { List, ListItem } from 'material-ui/List';
import Subheader from 'material-ui/Subheader';
import Divider from 'material-ui/Divider';

import { UserPropType, NotificationPropType } from 'common/constants/PropTypes';
import UserAvatar from '../utils/UserAvatar';
import createNotificationMessage from '../../utils/createNotificationMessage';
import createNotificationHref from '../../utils/createNotificationHref';

const NotificationsMenu = (props) => {
  const { notifications, user, open, anchorEl, onRequestClose } = props;
  if (!user) {
    return null;
  }

  const noNotifcationMsg = (notifications.length === 0) ?
    (
      <ListItem
        secondaryText={
          <p>お知らせはありません</p>
        }
      />
    ) :
    null;

  const moreLink = (notifications.length > 4) ?
    ([
      <Divider key="dMore" />,
      <div key="more" style={{ textAlign: 'center', padding: 5, fontSize: 14, textDecoration: 'none' }}>
        <a href="/notifications">すべて見る</a>
      </div>,
    ]) : null;

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      targetOrigin={{ horizontal: 'left', vertical: 'top' }}
      onRequestClose={onRequestClose}
      style={{ maxWidth: '90%', overflowY: 'auto' }}
    >
      <List>
        <Subheader>お知らせ</Subheader>
        {notifications.map(n => ([
          <Divider key={`d${n.id}`} />,
          <ListItem
            key={n.id}
            leftAvatar={(
              <UserAvatar
                user={n.notificationable.user}
                size={40}
              />
            )}
            secondaryText={createNotificationMessage(n)}
            secondaryTextLines={2}
            onClick={() => onRequestClose(null, createNotificationHref(n))}
          />,
        ]))}
        {moreLink}
        {noNotifcationMsg}
      </List>
    </Popover>
  );
};

NotificationsMenu.propTypes = {
  user: UserPropType,
  notifications: PropTypes.arrayOf(NotificationPropType),
  open: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  /* eslint-disable react/forbid-prop-types */
  anchorEl: PropTypes.object,
  /* eslint-enable react/forbid-prop-types */
};

NotificationsMenu.defaultProps = {
  user: null,
  open: false,
  notifications: [],
  anchorEl: null,
};

export default NotificationsMenu;
