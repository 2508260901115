import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { ActionTypes } from '../constants';
import entities from './entities';
import messages from './messages';
import paginate from './paginate';

const pagination = combineReducers({
  postPagination: handleActions(paginate(
    ActionTypes.FETCH_POSTS,
    ActionTypes.SUBMIT_POST.SUCCESS,
    ActionTypes.DESTROY_POST.SUCCESS,
  ), {}),
  articlePagination: handleActions(paginate(
    ActionTypes.FETCH_ARTICLES,
  ), {}),
});

const rootReducer = combineReducers({
  entities,
  messages,
  pagination,
});

export default rootReducer;
