import { createStore, applyMiddleware, compose } from 'redux';
import createLogger from 'redux-logger';
import { apiMiddleware } from 'redux-api-middleware';
import reducer from '../reducers';

export default function configureStore() {
  let enhancer = null;
  if (process.env.NODE_ENV !== 'production') {
    /* eslint-disable no-underscore-dangle */
    const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
    enhancer = composeEnhancers(applyMiddleware(createLogger(), apiMiddleware));
    /* eslint-enable no-underscore-dangle */
  } else {
    enhancer = applyMiddleware(apiMiddleware);
  }
  const store = createStore(reducer, {}, enhancer);
  return store;
}
