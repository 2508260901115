import React from 'react';
import PropTypes from 'prop-types';
import FontIcon from 'material-ui/FontIcon';

const TextIcon = ({ children, style, ...props }) => {
  const rootStyle = { verticaAlign: 'top' };
  return (
    <FontIcon
      style={Object.assign(rootStyle, style)}
      {...props}
    >
      {children}
    </FontIcon>
  );
};

TextIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.shape({}),
  children: PropTypes.node,
};

TextIcon.defaultProps = {
  color: null,
  style: {},
  children: null,
};

export default TextIcon;
