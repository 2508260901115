import React from 'react';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import Checkbox from 'material-ui/Checkbox';
import { red500 } from 'material-ui/styles/colors';
import { UserPropType } from 'common/constants/PropTypes';

const styles = {
  heading: {
    textAlign: 'center',
    fontSize: '1.2em',
    marginBottom: 8,
  },
  container: {
    padding: 10,
  },
  button: {
    marginTop: 10,
    marginBottom: 10,
  },
  error: {
    paddingBottom: 10,
    color: red500,
  },
  privacy: {
    padding: 8,
    marginBottom: 8,
    marginTop: 4,
    overflowY: 'auto',
    height: 200,
    border: '1px solid #ccc',
    fontSize: 'small',
  },
  privacyList: {
    marginLeft: '2rem',
    marginBottom: 8,
  },
};

const titles = [
  'サービス内容について',
  '違反報告',
  '利用者情報の取扱いについて',
  '機能に関するご意見・ご提案',
  '退会手続き',
  'その他のお問い合わせ',
];

const renderMenuItems = () => (
  titles.map(t => <MenuItem value={t} primaryText={t} key={t} />)
);

class InquiryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title || titles[0],
      agree: false,
    };
    this.handleChangeTitle = this.handleChangeTitle.bind(this);
    this.handleChangeAgree = this.handleChangeAgree.bind(this);
  }

  handleChangeTitle(event, index, title) {
    this.setState({ title });
  }

  handleChangeAgree() {
    this.setState({ agree: !this.state.agree });
  }

  renderErrors() {
    const { errors } = this.props;
    if (!errors) {
      return null;
    }

    return (
      <div>
        {errors.map(e => (
          <div key={e} style={styles.error}>{e}</div>
        ))}
      </div>
    );
  }

  render() {
    const { authenticityToken, email, content } = this.props;
    const defaultEmail = email || (this.context.user ? this.context.user.email : null);
    const { userAgent } = global.navigator;
    return (
      <div>
        <h2 style={styles.heading}>お問い合わせ</h2>
        {this.renderErrors()}
        <Paper style={styles.container}>
          <form action="/inquiries" method="POST">
            <input type="hidden" name="authenticity_token" value={authenticityToken} />
            <input type="hidden" name="inquiry[device_info]" value={`userAgent: ${userAgent}`} />
            <TextField
              floatingLabelText="メールアドレス"
              name="inquiry[email]"
              defaultValue={defaultEmail}
              type="email"
              fullWidth
              required
            />
            <SelectField
              onChange={this.handleChangeTitle}
              floatingLabelText="お問い合わせの種類"
              value={this.state.title}
              fullWidth
            >
              {renderMenuItems()}
            </SelectField>
            <input
              name="inquiry[title]"
              type="hidden"
              value={this.state.title}
            />
            <TextField
              floatingLabelText="お問い合わせ内容"
              name="inquiry[content]"
              defaultValue={content}
              fullWidth
              multiLine
              rows={4}
              required
            />
            <div style={styles.privacy}>
              【お問合せフォームでの個人情報の取り扱い】
              <ul style={styles.privacyList}>
                <li>ご記入いただいた内容は、お問合せへの回答にのみ利用し、これ以外の目的で利用することはありません。</li>
                <li>個人情報のご提供は任意です。ただし、お問合せフォームへの入力が不十分の場合、お問合せにご回答できない可能性がございます。</li>
                <li>本フォームに記入いただいた個人情報を第三者に提供したり、取り扱いを第三者に委託することはありません。</li>
                <li>そのほか、株式会社リサ・サーナの個人情報保護方針は<a href="https://risa-sana.co/privacypolicy" target="_blank" rel="noreferrer">こちら</a>をご覧ください。</li>
              </ul>
              【個人情報に関するお問合せ窓口】<br />
              株式会社リサ・サーナ<br />
              個人情報保護担当役員<br />
              E-mail：<a href="mailto:info@risa-sana.co">info@risa-sana.co</a><br />
              電話：045-910-3009<br />
              <br />
              ※当社では、保有個人データに関する利用目的の通知、開示、内容の訂正・追加または削除、利用の停止・消去
              および第三者への提供の停止の請求（「開示等の請求等」といいます。）を受け付けております。
              手続きについては、【個人情報に関するお問合せ窓口】へご連絡下さい。ただし、法令等の規定により開示等できない場合 がございます。あらかじめご了承ください。
            </div>
            <Checkbox
              label="個人情報の取り扱いに同意する"
              checked={this.state.agree}
              onCheck={this.handleChangeAgree}
            />
            <RaisedButton
              style={styles.button}
              label="送信"
              type="submit"
              disabled={!this.state.agree}
              primary
              fullWidth
            />
          </form>
        </Paper>
      </div>
    );
  }
}

InquiryPage.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  email: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
};

InquiryPage.defaultProps = {
  errors: [],
  email: null,
  title: null,
  content: null,
};

InquiryPage.contextTypes = {
  user: UserPropType,
};

export default InquiryPage;
