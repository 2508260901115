const createHeaders = (user, multipart = false) => {
  const headers = ({
    Accept: 'application/json',
  });
  if (user) {
    Object.assign(headers, {
      'X-User-Token': user.authentication_token,
      'X-User-Email': user.email,
    });
  }
  if (!multipart) {
    headers['Content-Type'] = 'application/json';
  }
  return headers;
};

export default createHeaders;
