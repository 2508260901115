import React from 'react';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import { red200 } from 'material-ui/styles/colors';
import ArticlesListItem from './ArticlesListItem';

const getStyles = () => {
  const styles = {
    container: {
      margin: '0 auto',
    },
    error: {
      textAlign: 'center',
      marginTop: 50,
      fontSize: 20,
      color: red200,
    },
    errorIcon: {
      fontSize: 60,
      color: red200,
    },
    articleContainer: {
      marginBottom: 10,
    },
  };
  return styles;
};

class ArticlesList extends React.Component {
  renderPaginateButton() {
    const { page, totalPage, isFetching, handlePaginateButton } = this.props;
    if (page >= totalPage || isFetching) {
      return null;
    }
    return (
      <RaisedButton
        label="もっと読む"
        fullWidth
        onClick={handlePaginateButton}
      />
    );
  }

  renderFetching() {
    if (!this.props.isFetching) {
      return null;
    }
    return (
      <div style={{ textAlign: 'center' }}>
        <CircularProgress size={80} />
      </div>
    );
  }

  renderEmpty() {
    if (this.props.isFetching || this.props.articles.length > 0) {
      return null;
    }

    const styles = getStyles();

    return (
      <div style={styles.error}>
        <div>
          <img
            src={global.PeerRing.images.logo_icon120}
            srcSet={`${global.PeerRing.images.logo_icon120_2x} 2x`}
            width={120}
            height={120}
            alt="Peer Ring Bleu"
          />
        </div>
        <div>まだ記事がありません</div>
      </div>
    );
  }

  renderArticleList() {
    const styles = getStyles();
    const articleList = this.props.articles.map(article => (
      <div
        key={article.id}
        style={styles.articleContainer}
      >
        <ArticlesListItem article={article} width={this.props.width} />
      </div>
    ));
    return <div>{articleList}</div>;
  }

  render() {
    const styles = getStyles();
    return (
      <div style={styles.container}>
        {this.renderEmpty()}
        {this.renderArticleList()}
        {this.renderPaginateButton()}
        {this.renderFetching()}
      </div>
    );
  }
}

ArticlesList.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isFetching: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  handlePaginateButton: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
};

export default ArticlesList;
