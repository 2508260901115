import React from 'react';
import PropTypes from 'prop-types';
import PostsListContainer from '../containers/PostsListContainer';
import theme from '../../constants/theme';

const styles = {
  heading: {
    textAlign: 'center',
    color: theme.palette.primary1Color,
    fontSize: '1.2em',
    marginBottom: 8,
  },
};

const SeachPage = props => (
  <div>
    <h2 style={styles.heading}>「{props.word}」の検索結果</h2>
    <PostsListContainer
      paginationKey={`SEARCH_WORDS_${encodeURIComponent(props.word)}`}
      searchParam={`word=${encodeURIComponent(props.word)}`}
    />
  </div>
);

SeachPage.propTypes = {
  word: PropTypes.string.isRequired,
};

export default SeachPage;
