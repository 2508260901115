import React from 'react';
import NotificationsListContainer from '../containers/NotificationsListContainer';

const styles = {
  heading: {
    textAlign: 'center',
    fontSize: '1.2em',
    marginBottom: 8,
  },
};

const NotificationPage = () => (
  <div>
    <h2 style={styles.heading}>お知らせ</h2>
    <NotificationsListContainer />
  </div>
);

export default NotificationPage;
