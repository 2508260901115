import React from 'react';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';
import TextField from 'material-ui/TextField';
import IconButton from 'material-ui/IconButton';
import CircularProgress from 'material-ui/CircularProgress';
import { List, ListItem } from 'material-ui/List';
import Divider from 'material-ui/Divider';
import ActionSearch from 'material-ui/svg-icons/action/search';
import UserAvatar from '../utils/UserAvatar';
import theme from '../../constants/theme';

const styles = {
  heading: {
    textAlign: 'center',
    color: theme.palette.primary1Color,
    fontSize: '1.2em',
    marginBottom: 8,
  },
  searchContainer: {
    marginTop: 20,
    padding: 10,
    display: 'flex',
  },
  searchText: {
    flex: 1,
  },
  searchButton: {
    verticalAlign: 'middle',
  },
  searchResult: {
    marginTop: 10,
  },
  profile: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  notFound: {
    marginTop: 20,
    color: '#f00',
  },
};

class UserListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: props.q || '',
      users: [],
      // keypress: false,
      // componentWillMount(fetchUsers)内でstateを変更をするとcomponentWillMountが
      // 再度呼ばれるので、ここでisFetcing=trueにしておく
      isFetching: true,
    };

    this.changeSearchText = this.changeSearchText.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    // this.handleKeyupSearch = this.handleKeyupSearch.bind(this);
    // this.handleKeypressSearch = this.handleKeypressSearch.bind(this);
  }

  componentWillMount() {
    this.fetchUsers();
  }

  fetchUsers() {
    const { searchText } = this.state;
    this.setState({ isFetching: true });
    const query = searchText ? `?q=${searchText}` : '';
    fetch(`/api/users${query}`)
      .then(response => response.json())
      .then(json => this.setState({ users: json.data, isFetching: false }))
      .catch(() => this.setState({ isFetching: false }));
  }

  changeSearchText(evt, searchText) {
    this.setState({ searchText });
  }

  handleSearch() {
    const { searchText } = this.state;
    let query = '';
    if (searchText && searchText.trim().length > 0) {
      query = `?q=${encodeURIComponent(searchText.trim())}`;
    }
    global.Turbolinks.visit(`/users${query}`);
  }

  /*
  handleKeypressSearch() {
    // IME変換中はKeyPessイベントが呼ばれない
    this.setState({ keypress: true });
  }

  handleKeyupSearch(event) {
    if (this.state.keypress && event.keyCode === 13) {
      this.handleSearch();
    }
    this.setState({ keypress: false });
  }
  */

  renderFetching() {
    if (!this.state.isFetching) {
      return null;
    }
    return (
      <div style={{ textAlign: 'center', margin: 20 }}>
        <CircularProgress size={80} />
      </div>
    );
  }

  renderUsers() {
    const { users, isFetching } = this.state;
    if (isFetching) {
      return null;
    }
    if (users.length === 0) {
      return <div style={styles.notFound}>検索した人が見つかりません</div>;
    }

    const listItems = this.state.users.reduce((r, user) => (
      r.concat([
        <ListItem
          key={user.id}
          leftAvatar={<UserAvatar user={user} />}
          primaryText={user.name}
          secondaryText={<div style={styles.profile}>{user.profile}</div>}
          href={`/users/${user.login}`}
        />,
        <Divider key={`d${user.id}`} />,
      ])
    ), []);
    listItems.pop(); // 最後のDividerを取り除く
    return (
      <Paper style={styles.searchResult}>
        <List>
          {listItems}
        </List>
      </Paper>
    );
  }

  render() {
    return (
      <div>
        <h2 style={styles.heading}>仲間を探そう</h2>
        <Paper style={styles.searchContainer}>
          <TextField
            style={styles.searchText}
            defaultValue={this.state.searchText}
            hintText="名前、病名、その他の検索ワード"
            hintStyle={{ fontSize: 12 }}
            // value={this.state.searchText}
            onChange={this.changeSearchText}
            // onKeyUp={this.handleKeyupSearch}
            // onKeyPress={this.handleKeypressSearch}
          />
          <IconButton
            style={styles.searchButton}
            onClick={this.handleSearch}
          >
            <ActionSearch />
          </IconButton>
        </Paper>
        {this.renderFetching()}
        {this.renderUsers()}
      </div>
    );
  }
}

UserListPage.propTypes = {
  q: PropTypes.string,
};

UserListPage.defaultProps = {
  q: '',
};

export default UserListPage;
