import React from 'react';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import CircularProgress from 'material-ui/CircularProgress';
import Subheader from 'material-ui/Subheader';
import FontIcon from 'material-ui/FontIcon';
import { List, ListItem } from 'material-ui/List';
import { green500 } from 'material-ui/styles/colors';
import { PostPropType } from 'common/constants/PropTypes';
import UserAvatar from '../utils/UserAvatar';
import CloverTextIcon from '../utils/CloverTextIcon';
import theme from '../../constants/theme';

const styles = {
  container: {
    fontSize: 14,
    marginTop: 3,
    paddingLeft: 10,
  },
};

class LikersButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      likers: [],
      loading: false,
    };
    this.userLoaded = false;
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleOpen() {
    const { post } = this.props;
    if (!this.userLoaded) {
      this.setState({ loading: true });
      this.userLoaded = true;
    }
    fetch(`/api/posts/${post.id}/likers`)
      .then(res => res.json())
      .then((json) => {
        this.setState({ likers: json, loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
    this.setState({ dialogOpen: true });
  }

  handleLinkToUser(user) {
    this.setState(
      { dialogOpen: false },
      () => global.Turbolinks.visit(`/users/${user.login}`),
    );
  }

  renderKindIcon(user) {
    const { post } = this.props;
    const kind = post.likes.users[`${user.id}`];
    if (kind === 'clover') {
      return <CloverTextIcon color={green500} style={{ fontSize: 24 }} />;
    }
    return <FontIcon className="fa fa-heart" color={theme.palette.primary1Color} />;
  }

  renderUserList() {
    if (this.state.loading) {
      return (
        <div style={{ textAlign: 'center', padding: 20 }}>
          <CircularProgress />
        </div>
      );
    }

    const { post } = this.props;

    const items = this.state.likers.map(user => (
      <ListItem
        key={user.id}
        onClick={() => this.handleLinkToUser(user)}
        primaryText={user.name}
        href={`/users/${user.login}`}
        leftAvatar={<UserAvatar user={user} />}
        rightIcon={this.renderKindIcon(user)}
      />
    ));

    let heart = null;
    if (Object.values(post.likes.users).includes('heart')) {
      heart = <FontIcon className="fa fa-heart" style={{ fontSize: 16 }} color={theme.palette.primary1Color} />;
    }

    let clover = null;
    if (Object.values(post.likes.users).includes('clover')) {
      clover = <CloverTextIcon style={{ fontSize: 16 }} />;
    }

    let count = items.length;
    if (count === 0) {
      count = Object.keys(post.likes.users).length;
    }

    return (
      <List>
        <Subheader>
          {`${count}人が`}
          {heart}{clover}
          しました
        </Subheader>
        {items}
      </List>
    );
  }

  renderDialog() {
    const actions = [
      <FlatButton
        label="閉じる"
        onClick={() => { this.setState({ dialogOpen: false }); }}
      />,
    ];

    return (
      <Dialog
        onRequestClose={() => { this.setState({ dialogOpen: false }); }}
        open={this.state.dialogOpen}
        actions={actions}
        contentStyle={{ maxWidth: 500 }}
        bodyStyle={{ padding: 0 }}
        autoScrollBodyContent
      >
        {this.renderUserList()}
      </Dialog>
    );
  }

  render() {
    const { post } = this.props;
    const likeCount = Object.keys(post.likes.users).length;

    if (likeCount === 0) {
      return null;
    }

    let heart = null;
    if (Object.values(post.likes.users).includes('heart')) {
      heart = <FontIcon className="fa fa-heart" style={{ fontSize: 16 }} color={theme.palette.primary1Color} />;
    }

    let clover = null;
    if (Object.values(post.likes.users).includes('clover')) {
      clover = <CloverTextIcon style={{ fontSize: 16 }} />;
    }

    return (
      <div // eslint-disable-line jsx-a11y/no-static-element-interactions
        style={styles.container}
        className="hover-underline"
        onClick={this.handleOpen}
      >
        {heart}
        {clover}
        {`${likeCount}人`}
        {this.renderDialog()}
      </div>
    );
  }
}

LikersButton.propTypes = {
  post: PostPropType.isRequired,
};

export default LikersButton;
