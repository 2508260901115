import React from 'react';
import PropTypes from 'prop-types';
import { green500 } from 'material-ui/styles/colors';
import TextIcon from './TextIcon';

const CloverTextIcon = ({ color, ...props }) => {
  const iconColor = color || green500;
  return <TextIcon {...props} color={iconColor}>&#x1f340;</TextIcon>;
};

CloverTextIcon.propTypes = {
  color: PropTypes.string,
};

CloverTextIcon.defaultProps = {
  color: null,
};

export default CloverTextIcon;
