import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton';
import FontIcon from 'material-ui/FontIcon';
import { PostPropType, UserPropType } from 'common/constants/PropTypes';
import theme from '../../constants/theme';

const styles = {
  icon: {
    width: 24,
    height: 24,
    fontSize: 24,
  },
  button: {
    width: 48,
    height: 48,
    padding: 9,
    marginRight: 24,
  },
};

class FavoriteButton extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { post, favoritePost, unfavoritePost } = this.props;
    const { user } = this.context;

    if (post.is_favorite) {
      unfavoritePost(post, user);
      return;
    }
    favoritePost(post, user);
  }

  renderIcon() {
    const { post } = this.props;
    if (post.is_favorite) {
      return <FontIcon title="お気に入り" className="fa fa-star" color={theme.palette.accent1Color} />;
    }
    return <FontIcon title="お気に入り" className="fa fa-star-o" />;
  }

  render() {
    const { user } = this.context;

    if (!user) {
      return null;
    }

    return (
      <span style={{ float: 'right' }}>
        <IconButton
          iconStyle={styles.icon}
          style={styles.button}
          disableTouchRipple
          onClick={this.handleClick}
        >
          {this.renderIcon()}
        </IconButton>
      </span>
    );
  }
}

FavoriteButton.propTypes = {
  post: PostPropType.isRequired,
  favoritePost: PropTypes.func.isRequired,
  unfavoritePost: PropTypes.func.isRequired,
};

FavoriteButton.defaultProps = {
};

FavoriteButton.contextTypes = {
  user: UserPropType,
};

export default FavoriteButton;
