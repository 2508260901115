import React from 'react';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';
import { List, ListItem } from 'material-ui/List';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import { UserPropType } from 'common/constants/PropTypes';
import UserAvatar from '../utils/UserAvatar';
import createNotificationMessage from '../../utils/createNotificationMessage';
import createNotificationHref from '../../utils/createNotificationHref';
import createHeaders from '../../utils/createHeaders';

class NotificationsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      totalPages: 0,
      currentPage: 0,
      loading: true,
    };
    this.fetchNotifications = this.fetchNotifications.bind(this);
  }

  componentDidMount() {
    const { user } = this.context;
    if (!user) {
      return;
    }
    this.fetchNotifications();
  }

  fetchNotifications() {
    const { currentPage, notifications } = this.state;
    const { user } = this.context;
    this.setState({ loading: true });
    fetch(`/api/notifications?page=${currentPage + 1}`, {
      headers: new Headers(createHeaders(user)),
    })
      .then(res => res.json())
      .then((json) => {
        this.setState({
          loading: false,
          notifications: notifications.concat(json.data),
          currentPage: json.meta.current_page,
          totalPages: json.meta.total_pages,
        });
      })
      .catch(() => {
        this.props.addMessage('エラーが発生しました。');
      });
  }

  renderNext() {
    const { totalPages, currentPage, loading } = this.state;

    if (loading) {
      return null;
    }

    if (currentPage >= totalPages) {
      return null;
    }

    return (
      <RaisedButton
        style={{ marginTop: 10 }}
        label="もっと見る"
        fullWidth
        onClick={this.fetchNotifications}
      />
    );
  }

  renderLoading() {
    const { loading } = this.state;
    if (!loading) {
      return null;
    }
    return (
      <div style={{ textAlign: 'center', paddingBottom: 20 }}>
        <CircularProgress />
      </div>
    );
  }

  render() {
    const { notifications, loading } = this.state;

    if (notifications.length === 0 && !loading) {
      return <div style={{ textAlign: 'center' }}>お知らせはありません</div>;
    }

    const items = notifications.map(n => (
      <ListItem
        key={n.id}
        leftAvatar={(
          <UserAvatar
            user={n.notificationable.user}
            size={40}
          />
        )}
        secondaryText={createNotificationMessage(n)}
        secondaryTextLines={2}
        href={createNotificationHref(n)}
      />
    ));

    return (
      <div>
        <Paper>
          <List>
            {items}
          </List>
          {this.renderLoading()}
        </Paper>
        {this.renderNext()}
      </div>
    );
  }
}

NotificationsList.propTypes = {
  addMessage: PropTypes.func.isRequired,
};

NotificationsList.contextTypes = {
  user: UserPropType,
};

export default NotificationsList;
