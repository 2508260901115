import React from 'react';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';

const styles = {
  container: {
    padding: 10,
  },
  additional: {
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
  button: {
    marginTop: 30,
    marginBottom: 20,
  },
};

const SignIn = (props) => {
  const { authenticityToken } = props;
  return (
    <div>
      <Paper style={styles.container}>
        <h2>ログイン</h2>
        <form action="/users/sign_in" method="post">
          <input type="hidden" name="authenticity_token" value={authenticityToken} />
          <input type="hidden" name="user[remember_me]" value="1" />
          <TextField
            hintText="ユーザ名またはメールアドレスを入力"
            floatingLabelText="ユーザ名またはメールアドレス"
            name="user[login_or_email]"
            defaultValue={props.login_or_email}
            fullWidth
            required
          />
          <TextField
            hintText="パスワードを入力"
            floatingLabelText="パスワード"
            name="user[password]"
            type="password"
            fullWidth
            required
          />
          <RaisedButton
            style={styles.button}
            label="ログイン"
            type="submit"
            primary
            fullWidth
          />
        </form>
        <div style={styles.additional}><a href="/users/password/new">パスワードを忘れたら</a></div>
      </Paper>
      <div style={styles.additional}><a href="/users/sign_up">まだアカウントをお持ちでない方はこちら</a></div>
    </div>
  );
};

SignIn.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  login_or_email: PropTypes.string,
};

SignIn.defaultProps = {
  login_or_email: null,
};

export default SignIn;
