import React from 'react';
import PropTypes from 'prop-types';
import loadImage from 'blueimp-load-image';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import IconButton from 'material-ui/IconButton';
import ActionFace from 'material-ui/svg-icons/action/face';
import ActionQuestionAnswer from 'material-ui/svg-icons/action/question-answer';
import ActionEvent from 'material-ui/svg-icons/action/event';
import ImageAddAPhoto from 'material-ui/svg-icons/image/add-a-photo';
import SelectField from 'material-ui/SelectField';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import TextField from 'material-ui/TextField';
import { grey300 } from 'material-ui/styles/colors';
import { UserPropType, PostPropType, CategoryPropType } from 'common/constants/PropTypes';
import { DIARY, QUESTION, EVENT } from '../../constants/PostTypes';

const styles = {
  fileInput: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    opacity: 0,
  },
  selectedImageWrapper: {
    width: 80,
    height: 80,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'middle',
    padding: 8,
    position: 'relative',
    overflow: 'hidden',
  },
  selectedImage: {
    minWidth: '100%',
    minHeight: '100%',
    width: '100%',
    poistion: 'relative',
  },
  selectedImageCloseWrapper: {
    position: 'absolute',
    top: -10,
    right: 1,
    width: 32,
    height: 32,
  },
  selectedImageClose: {
    width: 32,
    height: 32,
  },
  dialogContentStyle: {
    minWidth: 320,
    maxWidth: '100%',
  },
};

class EditDialog extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      type: DIARY,
      category: '',
      files: [],
      audience: 'public',
      content: '',
      deleteImageIds: [],
    };

    this.textField = null;
    this.handleChangeTextField = this.handleChangeTextField.bind(this);
    this.handleChangeCategory = this.handleChangeCategory.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFileInput = this.handleFileInput.bind(this);
    this.handleDeleteImage = this.handleDeleteImage.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open) {
      this.initState();
    }
  }

  getContent() {
    if (!this.textField) {
      return '';
    }
    return this.textField.getValue();
  }

  setContent(content) {
    if (!this.textField) {
      return;
    }
    this.textField.getInputNode().value = content;
    this.setState({ content });
  }

  initState() {
    const { post } = this.props;
    let state = {
      type: DIARY,
      category: '',
      files: [],
      audience: 'public',
      deleteImageIds: [],
    };

    if (post) {
      state = {
        type: post.type,
        category: post.category || '',
        files: [],
        audience: post.audience,
        content: post.content,
        deleteImageIds: [],
      };
    }
    this.setState(state);
    this.setContent(state.content);
  }

  handleChangeTextField() {
    this.setState({ content: this.getContent() });
  }

  handleChangeCategory(event, index, category) {
    const { categories } = this.context;
    if (!categories.map(c => c.name).includes(category)) {
      this.setState({ category: '' });
      return;
    }
    this.setState({ category });
  }

  handleFileInput(event) {
    const input = event.target;
    const file = input.files[0];
    const self = this;

    loadImage.parseMetaData(file, (data) => {
      const options = {
        canvas: true,
      };
      if (data.exif) {
        options.orientation = data.exif.get('Orientation');
      }
      loadImage(file, (canvas) => {
        const src = canvas.toDataURL('image/jpeg');
        self.setState({
          files: self.state.files.concat({ src, file }),
        });
        input.value = null;
      }, options);
    });
  }

  handleRemoveSelectedFile(index) {
    const files = [].concat(this.state.files);
    files.splice(index, 1);
    this.setState({ files });
  }

  handleDeleteImage(imgId) {
    const { deleteImageIds } = this.state;
    this.setState({ deleteImageIds: deleteImageIds.concat(imgId) });
  }

  handleSubmit() {
    const { post } = this.props;
    const { type, content, category, files, audience, deleteImageIds } = this.state;
    if (content && content.length > 0) {
      const { user, onRequestClose } = this.props;
      const newPost = {
        type,
        content: content.trim(),
        category: category || '',
        images: files.map(f => f.file),
        audience: type === QUESTION ? 'public' : audience,
      };

      if (post) {
        newPost.id = post.id;
        this.props.updatePost(newPost, user, true, deleteImageIds);
      } else {
        this.props.submitPost(newPost, user, true);
      }
      onRequestClose();
    }
  }

  canSubmit() {
    const { content, category, type } = this.state;

    if (!content || content.length === 0) {
      return false;
    }

    if (type === QUESTION && category.length === 0) {
      return false;
    }

    return true;
  }

  renderCategory() {
    const { categories } = this.context;
    const menuItems = categories.map(c => (
      <MenuItem key={c.name} value={c.name} primaryText={c.name} />
    ));

    if (this.state.type !== QUESTION) {
      menuItems.push(<MenuItem key="null" value={0} primaryText="（選択しない）" />);
    }

    return (
      <div style={{ marginTop: -15, marginBottom: -10 }}>
        <SelectField
          floatingLabelText="カテゴリを選択してください"
          value={this.state.category}
          onChange={this.handleChangeCategory}
          fullWidth
        >
          {menuItems}
        </SelectField>
      </div>
    );
  }

  renderFileSelection() {
    const { post } = this.props;
    const { deleteImageIds } = this.state;

    const exsitingImages = post ? (
      post.images.filter(img => !deleteImageIds.includes(img.id)).map(img => (
        <div style={styles.selectedImageWrapper} key={img.id}>
          <img style={styles.selectedImage} src={img.file_url} alt="" />
          <div style={styles.selectedImageCloseWrapper}>
            <IconButton
              style={styles.selectedImageClose}
              iconClassName="fa fa-times-circle-o"
              onClick={() => { this.handleDeleteImage(img.id); }}
            />
          </div>
        </div>
      ))
    ) : null;

    const selectedImages = this.state.files.map((f, idx) => (
      <div style={styles.selectedImageWrapper} key={f.file.name}>
        <img style={styles.selectedImage} src={f.src} alt="" />
        <div style={styles.selectedImageCloseWrapper}>
          <IconButton
            style={styles.selectedImageClose}
            iconClassName="fa fa-times-circle-o"
            onClick={() => { this.handleRemoveSelectedFile(idx); }}
          />
        </div>
      </div>
    ));

    const disabled = this.state.files && this.state.files.length > 9;

    return (
      <div>
        <div>
          {exsitingImages}
          {selectedImages}
        </div>
        <div style={{ paddingBottom: 3, paddingTop: 3 }}>
          <FlatButton
            label="画像をアップロード"
            containerElement="label"
            icon={<ImageAddAPhoto />}
            disabled={disabled}
          >
            <input
              type="file"
              accept="image/*"
              style={styles.fileInput}
              onChange={this.handleFileInput}
              disabled={disabled}
            />
          </FlatButton>
        </div>
      </div>
    );
  }

  renderEditDialog() {
    const { open, onRequestClose, post } = this.props;

    const actions = [
      <FlatButton
        label="キャンセル"
        onClick={onRequestClose}
      />,
      <FlatButton
        label={post ? '更新' : '投稿'}
        primary
        onClick={this.handleSubmit}
        disabled={!this.canSubmit()}
      />,
    ];

    actions.unshift((
      <DropDownMenu
        value={this.state.audience}
        onChange={(e, i, audience) => { this.setState({ audience }); }}
        style={{ float: 'left' }}
        labelStyle={{ lineHeight: '40px', paddingLeft: 16, paddingRight: 42, fontSize: 13 }}
        iconStyle={{ padding: 4, width: 32, height: 32 }}
        underlineStyle={{ border: 'none' }}
      >
        <MenuItem value="public" primaryText="公開" />
        <MenuItem value="members" primaryText="会員限定" />
      </DropDownMenu>
    ));

    let hintText = null;
    switch (this.state.type) {
      case QUESTION:
        hintText = <span>診察室未満のモヤモヤを、メンバーに投げかけてみましょう。</span>;
        break;
      case EVENT:
        hintText = <span>イベントをメンバーに呼びかけてみましょう。</span>;
        break;
      default:
        hintText = <span>治療のこと、日々のがんばり・想いなどを自由に記録してみましょう。</span>;
        break;
    }
    if (this.getContent() && this.getContent().length > 0) {
      // window resize時にhintTextでサイズを決定してしまう不具合があるため
      hintText = null;
    }

    const buttonStyles = {
      diary: {},
      question: {},
      event: {},
    };

    switch (this.state.type) {
      case QUESTION:
        buttonStyles.diary = { color: grey300 };
        buttonStyles.event = { color: grey300 };
        break;
      case EVENT:
        buttonStyles.diary = { color: grey300 };
        buttonStyles.question = { color: grey300 };
        break;
      default:
        buttonStyles.event = { color: grey300 };
        buttonStyles.question = { color: grey300 };
        break;
    }

    return (
      <Dialog
        actions={actions}
        open={open}
        contentStyle={styles.dialogContentStyle}
        onRequestClose={onRequestClose}
        modal
        autoScrollBodyContent
      >
        <div>
          <FlatButton
            labelStyle={buttonStyles.diary}
            label="ダイアリー"
            icon={<ActionFace color={buttonStyles.diary.color} />}
            onClick={() => { this.setState({ type: DIARY }); }}
            primary={this.state.type === DIARY}
          />
          <FlatButton
            labelStyle={buttonStyles.question}
            label="質問"
            icon={<ActionQuestionAnswer color={buttonStyles.question.color} />}
            onClick={() => { this.setState({ type: QUESTION }); }}
            primary={this.state.type === QUESTION}
          />
          <FlatButton
            labelStyle={buttonStyles.event}
            label="イベント"
            icon={<ActionEvent color={buttonStyles.event.color} />}
            onClick={() => { this.setState({ type: EVENT }); }}
            primary={this.state.type === EVENT}
          />
        </div>
        {this.renderCategory()}
        <TextField
          hintText={hintText}
          multiLine
          rows={7}
          fullWidth
          underlineShow={false}
          hintStyle={{ bottom: null, top: 12 }}
          name="post[content]"
          // stateからvalueセットするとうまくいかないので、refからvalueをとってstateにセットする
          ref={(f) => { this.textField = f; }}
          value={this.textField ? undefined : this.state.content}
          onChange={this.handleChangeTextField}
        />
        {this.renderFileSelection()}
      </Dialog>
    );
  }

  renderSigninDialog() {
    const { open, onRequestClose } = this.props;

    const actions = [
      <FlatButton
        label="キャンセル"
        onClick={onRequestClose}
      />,
      <FlatButton
        label="無料会員登録"
        href="/users/sign_up"
      />,
      <FlatButton
        label="ログイン"
        href="/users/sign_in"
      />,
    ];

    return (
      <Dialog
        title="Peer Ring Bleuにログイン"
        actions={actions}
        open={open}
        onRequestClose={onRequestClose}
        modal
        contentStyle={{ maxWidth: '100%', width: 400 }}
      >
        投稿するにはログインが必要です。
      </Dialog>
    );
  }

  render() {
    if (this.props.user) {
      return this.renderEditDialog();
    }
    return this.renderSigninDialog();
  }
}

EditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  user: UserPropType,
  submitPost: PropTypes.func.isRequired,
  updatePost: PropTypes.func.isRequired,
  post: PostPropType,
};

EditDialog.defaultProps = {
  user: null,
  post: null,
};

EditDialog.contextTypes = {
  categories: PropTypes.arrayOf(CategoryPropType).isRequired,
};

export default EditDialog;
