import merge from 'lodash/merge';
import union from 'lodash/union';
import { ActionTypes } from '../constants';

const actionToKey = action => (action.meta ? action.meta.paginationKey : null);

const paginate = (types, reloadAction, destroyAction) => ({
  [types.REQUEST]: (state, action) => {
    const key = actionToKey(action);
    if (state.requireRefresh) {
      return merge({}, state, { [key]: { isRefreshing: true, requireRefresh: false } });
    }
    return merge({}, state, { [key]: { isFetching: true } });
  },
  [types.SUCCESS]: (state, action) => {
    const key = actionToKey(action);

    // 1ページ目の場合は最初につける
    const ids = action.payload.meta.current_page === 1 ?
      union(action.payload.data.map(d => d.id), state[key].ids) :
      union(state[key].ids, action.payload.data.map(d => d.id));

    const updatedState = ({
      isRefreshing: false,
      isFetching: false,
      ids,
      totalPage: action.payload.meta.total_pages,
      page: action.payload.meta.current_page,
      requireRefresh: false,
    });
    return merge({}, state, { [key]: updatedState });
  },
  [types.FAILURE]: (state, action) => merge(
    {},
    state,
    { [actionToKey(action)]: { isFetching: false, isRefreshing: false } },
  ),
  [reloadAction]: (state) => {
    const newState = Object.keys(state).reduce((p, c) => merge(
      {},
      p,
      { [c]: { requireRefresh: true } },
    ), {});
    return merge({}, state, newState);
  },
  [destroyAction]: (state, action) => {
    const newState = merge({}, state);
    Object.keys(newState).forEach((key) => {
      const index = newState[key].ids.indexOf(action.meta.id);
      newState[key].ids.splice(index, 1);
    });
    return newState;
  },
  [ActionTypes.FAVORITE_POST.SUCCESS]: (state) => {
    if (!state.FAVORITES) {
      return state;
    }
    return merge({}, state, { FAVORITES: { requireRefresh: true } });
  },
  [ActionTypes.UNFAVORITE_POST.SUCCESS]: (state, action) => {
    if (!state.FAVORITES) {
      return state;
    }
    const newState = { ...state };
    newState.FAVORITES.ids = (state.FAVORITES.ids || []).filter((id) => id !== action.meta.postId);
    return newState;
  },
});

export default paginate;
