import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PostPropType, AdsPropType } from 'common/constants/PropTypes';
import { fetchPosts } from '../../actionCreators';
import PostsList from '../posts/PostsList';

class PostsListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handlePaginateButton = this.handlePaginateButton.bind(this);
  }

  componentWillMount() {
    const {
      posts, isFetching, isRefreshing, paginationKey, searchParam, requireRefresh,
    } = this.props;
    if (requireRefresh || (posts.length === 0 && !isFetching && !isRefreshing)) {
      this.props.fetchPosts(1, paginationKey, searchParam);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.requireRefresh && !this.props.requireRefresh) {
      const { paginationKey, searchParam } = this.props;
      this.props.fetchPosts(1, paginationKey, searchParam);
    }
  }

  handlePaginateButton() {
    const { page, paginationKey, searchParam } = this.props;
    this.props.fetchPosts(page + 1, paginationKey, searchParam);
  }

  render() {
    const { posts, ads, isFetching, isRefreshing, page, totalPage } = this.props;
    return (
      <PostsList
        posts={posts}
        ads={ads}
        isFetching={isFetching}
        isRefreshing={isRefreshing}
        page={page}
        totalPage={totalPage}
        handlePaginateButton={this.handlePaginateButton}
      />
    );
  }
}

PostsListContainer.propTypes = {
  posts: PropTypes.arrayOf(PostPropType).isRequired,
  ads: AdsPropType,
  isFetching: PropTypes.bool.isRequired,
  isRefreshing: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  requireRefresh: PropTypes.bool.isRequired,
  fetchPosts: PropTypes.func.isRequired,
  paginationKey: PropTypes.string.isRequired,
  searchParam: PropTypes.string,
};

PostsListContainer.defaultProps = {
  searchParam: null,
  ads: {},
};

const mapStateToProps = (state, ownProps) => {
  const { pagination, entities: { posts, ads } } = state;
  const timeline = pagination.postPagination[ownProps.paginationKey] || {};
  const ids = timeline.ids || [];

  const thisAds = {};
  if (ownProps.paginationKey === 'HOME') {
    Object.keys(ads).forEach((priority) => {
      const newAd = Object.assign({}, ads[priority]);
      newAd.post = posts[newAd.postId];
      thisAds[priority] = newAd;
      ids.some((id, i) => (id === newAd.post.id ? ids.splice(i, 1) : null));
    });
  }

  return {
    posts: ids.map(id => posts[id]),
    ads: thisAds,
    isFetching: timeline.isFetching || false,
    isRefreshing: timeline.isRefreshing || false,
    page: timeline.page || 1,
    totalPage: timeline.totalPage || 0,
    requireRefresh: timeline.requireRefresh || false,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({ fetchPosts }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PostsListContainer);
