import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchArticles } from '../../actionCreators';
import ArticlesList from '../articles/ArticlesList';

class ArticlesListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handlePaginateButton = this.handlePaginateButton.bind(this);
  }

  componentWillMount() {
    const { articles, isFetching } = this.props;
    if (articles.length === 0 && !isFetching) {
      this.props.fetchArticles(1);
    }
  }

  handlePaginateButton() {
    const { page } = this.props;
    this.props.fetchArticles(page + 1);
  }

  render() {
    const { articles, isFetching, page, totalPage, width } = this.props;
    return (
      <ArticlesList
        articles={articles}
        isFetching={isFetching}
        page={page}
        totalPage={totalPage}
        handlePaginateButton={this.handlePaginateButton}
        width={width}
      />
    );
  }
}

ArticlesListContainer.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isFetching: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  fetchArticles: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  const { pagination, entities: { articles } } = state;
  const timeline = pagination.articlePagination.ARTICLES || {};
  const ids = timeline.ids || [];

  return {
    articles: ids.map(id => articles[id]),
    isFetching: timeline.isFetching || false,
    page: timeline.page || 1,
    totalPage: timeline.totalPage || 0,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({ fetchArticles }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesListContainer);
