import $ from 'jquery';

const saveFacebookRoot = () => {
  if ($('#fb-root').length > 0) {
    global.fbRoot = $('#fb-root').detach();
  }
};

const restoreFacebookRoot = () => {
  if (!global.fbRoot) {
    return;
  }
  if ($('#fb-root').length > 0) {
    $('#fb-root').replaceWith(global.fbRoot);
    return;
  }
  $('body').append(global.fbRoot);
};

const initializeFacebookSDK = () => {
  global.FB.init({
    appId: '1666158627023824',
    status: true,
    cookie: true,
    xfbml: true,
    version: 'v2.8',
  });
};

const loadFacebookSDK = () => {
  global.fbAsyncInit = initializeFacebookSDK;
  $.getScript('//connect.facebook.net/ja_JP/sdk.js');
};

const bindFacebookEvents = () => {
  $(document)
    .on('turbolinks:request-start', saveFacebookRoot)
    .on('turbolinks:render', restoreFacebookRoot)
    .on('turbolinks:load', () => { global.FB.XFBML.parse(); });
  global.fbEventsBound = true;
};

const loadFacebook = () => {
  $(document).ready(() => {
    loadFacebookSDK();
    if (global.fbEventsBound) {
      return;
    }
    bindFacebookEvents();
  });
};

export default loadFacebook;
