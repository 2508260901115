const ImgServer = global.PeerRing.ImgServer;

const convertImgSrc = (src, w, h, crop = false) => {
  if (!ImgServer || ImgServer.length === 0) {
    return src;
  }

  let imgSrc = src;
  if (src.startsWith(ImgServer)) {
    const iw = Math.min(window.innerWidth, w);
    const ih = Math.min(window.innerHeight, h);
    const typeCrop = crop ? '&type=crop' : '';
    imgSrc = `${src}?width=${iw}&height=${ih}${typeCrop}`;
  }
  return imgSrc;
};

export default convertImgSrc;
