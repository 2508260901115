import React from 'react';
import FontIcon from 'material-ui/FontIcon';
import displayDateTime from './displayDateTime';
import CloverTextIcon from '../components/utils/CloverTextIcon';
import theme from '../constants/theme';

const createNotificationMessage = (notification) => {
  let message = `${notification.notificationable.user.name}さんが`;
  switch (notification.notificationable.type) {
    case 'Comment':
      message = `${message}投稿にコメントしました。`;
      break;
    case 'Like':
      if (notification.notificationable.likeable.type === 'Post') {
        if (notification.notificationable.kind === 'clover') {
          message = (
            <span>
              {message}
              投稿に
              <CloverTextIcon style={{ fontSize: 16 }} />
              しました。
            </span>
          );
        } else {
          message = (
            <span>
              {message}
              投稿に
              <FontIcon className="fa fa-heart" style={{ fontSize: 16 }} color={theme.palette.primary1Color} />
              しました。
            </span>
          );
        }
      } else if (notification.notificationable.likeable.type === 'Comment') {
        message = `${message}コメントにいいね！しました。`;
      } else {
        message = `${message}いいね！しました。`;
      }
      break;
    case 'Mention':
      if (notification.notificationable.mentionable.type === 'Post') {
        message = `${message}あなた宛の投稿をしました。`;
      } else if (notification.notificationable.mentionable.type === 'Comment') {
        message = `${message}あなた宛のコメントをしました。`;
      } else {
        message = `${message}あなた宛の投稿をしました。`;
      }
      break;
    default:
      message = `${message}リアクションをしました。`;
      break;
  }

  return (
    <div style={{ fontSize: 'small', position: 'relative' }}>
      {message}
      <div style={{ position: 'absolute', right: 0, bottom: 0, backgroundColor: 'white', paddingLeft: 5 }}>
        {displayDateTime(notification.created_at)}
      </div>
    </div>
  );
};

export default createNotificationMessage;
