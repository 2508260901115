
const PeerRing = {};
PeerRing.ImgServer = 'https://i.bleu.peer-ring.com';
PeerRing.images = {
  logo_title: "/assets/logo_title-312e303c23a467fd841e2c6e79339ff0e76ed75b948444713825397d61d540456eae3f.png",
  logo_title_2x: "/assets/logo_title@2x-312e3086056db20da8c0a5f1b05aed0c9493e5ea98fe70bda8eb59960ae19c3565c0cf.png",
  logo_icon: "/assets/logo_icon-312e30dcce69bbd899b5ac5a909a02afc3fc76284d616c76e28e1dd0efa826139b9848.png",
  logo_icon_2x: "/assets/logo_icon@2x-312e30310d6f181f97ce6190a0f856b372ede27a0598fddffa0264ed88ec15266e4525.png",
  logo_icon120: "/assets/logo_icon120-312e30630b724ee704bcdd7dddac3b6117522b515b2dddfad214ea73f91fba43a494a4.png",
  logo_icon120_2x: "/assets/logo_icon120@2x-312e30ebbd845f56e2bdeaa57eac06f0cff1b6a161c9788dc61d67ab814db408596365.png",
  topImage: "/assets/top-image-312e309cf6678fc7d68fab5e02d8d1e9349c7a9ec4e1a08962853e1d018bfa8ab549f5.jpg",
  about: [
    "/assets/about01-312e3034b3464959fc1b1caa568e9c6a06deb374ff7f631a54879e7ccd093e546983e5.jpg",
    "/assets/about02-312e30d83289844bddb0c7d262292b3a5491dd691f9c0c29caf397eb34a7ab0babaff3.jpg",
    "/assets/about03-312e3007eee2edd1ed0574f46ba83a2dfcd11ae3ecc00b5598fecf64d59e0432740e9b.jpg",
    "/assets/about04-312e3032a044de20d83ddd67d901a58fa132b131ad576903b64aaf158ae56f1157605c.jpg",
    "/assets/about05-312e3027b108e057586213de91edb0d35670829677cbe2818687629f1a66870e272987.jpg",
  ],
  badge_download_on_appstore: "/assets/badge-download-on-the-app-store-jp-312e30988fe0a48015c5a56dac88172487a1f5cda96340b34d80fd94312d02e9f67865.svg",
  google_play_badge: "/assets/google-play-badge-312e309109ebbe6a617995eeb0d623a924ce362c112094a5e21bfcae42ea0852f47148.png",
};
PeerRing.RailsEnv = 'production';

if (!global.PeerRing) {
  global.PeerRing = {};
}
global.PeerRing = Object.assign({}, global.PeerRing, PeerRing);
