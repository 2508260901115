import React from 'react';
import ReactDOM from 'react-dom';
import { Tabs, Tab } from 'material-ui/Tabs';
import { UserPropType } from 'common/constants/PropTypes';

const styles = {
  container: {
    paddingBottom: 5,
    overflowX: 'auto',
    width: '100%',
  },
  tabContainer: {
    display: 'inline-block',
  },
};

class NavTabs extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.tabValue = `${location.pathname}`;
    this.activeTab = null;
    this.setActiveTab = this.setActiveTab.bind(this);
  }

  /* eslint-disable react/no-find-dom-node */
  componentDidMount() {
    if (this.activeTab) {
      // this.activeTab はDOMではない(Tab Component)ので、DOMに変換する必要がある
      ReactDOM.findDOMNode(this.activeTab).scrollIntoView(false);
    }
  }
  /* eslint-enable */

  setActiveTab(c) {
    if (c && c.props && c.props.selected) {
      this.activeTab = c;
    }
  }

  render() {
    if (global.PeerRing.embeded) {
      return null;
    }

    const { user } = this.context;

    return (
      <div style={styles.container}>
        <Tabs
          value={this.tabValue}
        >
          <Tab
            label="新着"
            value="/"
            href="/"
            style={styles.tab}
            ref={this.setActiveTab}
          />
          <Tab
            label="ダイアリー"
            value="/diaries"
            href="/diaries"
            style={styles.tab}
            ref={this.setActiveTab}
          />
          <Tab
            label="質問"
            value="/questions"
            href="/questions"
            style={styles.tab}
            ref={this.setActiveTab}
          />
          <Tab
            label="イベント"
            value="/events"
            href="/events"
            style={styles.tab}
            ref={this.setActiveTab}
          />
          {user && (
            <Tab
              label="お気に入り"
              value="/favorites"
              href="/favorites"
              style={styles.tab}
              ref={this.setActiveTab}
            />
          )}
          <Tab
            label="記事"
            value="/articles"
            href="/articles"
            style={styles.tab}
            ref={this.setActiveTab}
          />
        </Tabs>
      </div>
    );
  }
}

NavTabs.contextTypes = {
  user: UserPropType,
};

export default NavTabs;
