import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { addMessage } from '../../actionCreators';
import NotificationsList from '../notifications/NotificationsList';

const NotificationsListContainer = props => <NotificationsList addMessage={props.addMessage} />;

NotificationsListContainer.propTypes = {
  addMessage: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({ addMessage }, dispatch);

export default connect(null, mapDispatchToProps)(NotificationsListContainer);
