import React from 'react';

const nl2br = (text) => {
  if (!text) {
    return [];
  }
  const regex = /(\n)/g;
  let key = 0;
  return text.split(regex).map((line) => {
    if (line.match(regex)) {
      key += 1;
      return React.createElement('br', { key });
    }
    return line;
  });
};

export default nl2br;
