import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UserPropType } from 'common/constants/PropTypes';
import EditDialog from '../layouts/EditDialog';
import { submitPost, updatePost } from '../../actionCreators';

const EditDialogContainer = props => <EditDialog {...props} />;

EditDialogContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  user: UserPropType,
  submitPost: PropTypes.func.isRequired,
  updatePost: PropTypes.func.isRequired,
};

EditDialogContainer.defaultProps = {
  user: null,
};

const mapDispatchToProps = dispatch => bindActionCreators({ submitPost, updatePost }, dispatch);

export default connect(null, mapDispatchToProps)(EditDialogContainer);
