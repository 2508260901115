import $ from 'jquery';

global.twttrEventsBound = false;

const loadTwitterSDK = (callback) => {
  $.getScript('//platform.twitter.com/widgets.js', callback);
};

const renderTweetButtons = () => {
  $('.twitter-share-button').each(() => {
    const button = $(this);
    if (!button.data('url')) {
      button.attr('data-url', document.location.href);
    }
    if (!button.data('text')) {
      button.attr('data-text', document.title);
    }
  });
  global.twttr.widgets.load();
};

const bindTwitterEventHandlers = () => {
  $(document).on('turbolinks:load', renderTweetButtons);
  global.twttrEventsBound = true;
};

const loadTwitter = () => {
  $(document).ready(() => {
    loadTwitterSDK(() => {
      if (global.twttrEventsBound) {
        return;
      }
      bindTwitterEventHandlers();
    });
  });
};

export default loadTwitter;
