import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Snackbar from 'material-ui/Snackbar';
import { addMessage, removeMessage } from '../../actionCreators';

class BriefMessage extends React.Component {
  componentWillMount() {
    this.props.initialMessages.forEach(m => this.props.addMessage(m));
  }

  render() {
    const { messages } = this.props;
    if (messages.length === 0) {
      return null;
    }

    const message = messages[0].message;

    return (
      <Snackbar
        open
        message={message}
        autoHideDuration={4000}
        onRequestClose={() => { this.props.removeMessage(message); }}
      />
    );
  }
}

BriefMessage.propTypes = {
  initialMessages: PropTypes.arrayOf(PropTypes.string),
  messages: PropTypes.arrayOf(PropTypes.shape(
    { message: PropTypes.string },
  )).isRequired,
  addMessage: PropTypes.func.isRequired,
  removeMessage: PropTypes.func.isRequired,
};

BriefMessage.defaultProps = {
  initialMessages: [],
};

const mapStateToProps = state => ({ messages: state.messages });

const mapDispatchToProps = dispatch => bindActionCreators(
  { addMessage, removeMessage },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(BriefMessage);
