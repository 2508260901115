import React from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MuiThemeProvider as V0MuiThemeProvider } from 'material-ui';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import AppBar from 'material-ui/AppBar';
import { red500 } from 'material-ui/styles/colors';
import UserFooter from './UserFooter';
import theme from '../../constants/theme';

const muiTheme = createMuiTheme();

const muiThemeV0 = getMuiTheme(theme);

const styles = {
  appBar: {
    textAlign: 'center',
  },
  appBarTitle: {
    height: 56,
    lineHeight: '56px',
    cursor: 'pointer',
  },
  appBarTitleImg: {
    marginTop: 4,
  },
  container: {
    width: 540,
    maxWidth: '100%',
    margin: '20px auto',
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 50,
  },
  notice: {
    paddingBottom: 10,
  },
};

function renderAlert(alert, isAlert) {
  if (!alert) {
    return null;
  }
  const style = isAlert ? Object.assign({ color: red500 }, styles.notice) : styles.notice;
  return <div style={style}>{alert}</div>;
}

const UserLayout = props => (
  <MuiThemeProvider theme={muiTheme}>
    <V0MuiThemeProvider muiTheme={muiThemeV0}>
      <div>
        <AppBar
          title={(
            <img
              src={global.PeerRing.images.logo_title}
              srcSet={`${global.PeerRing.images.logo_title_2x} 2x`}
              style={styles.appBarTitleImg}
              alt="Peer Ring Bleu"
            />
          )}
          titleStyle={styles.appBarTitle}
          style={styles.appBar}
          showMenuIconButton={false}
          onTitleTouchTap={() => { global.Turbolinks.visit('/'); }}
        />
        <div style={styles.container}>
          {renderAlert(props.alert, true)}
          {renderAlert(props.notice, false)}
          {props.children}
        </div>
        <UserFooter />
      </div>
    </V0MuiThemeProvider>
  </MuiThemeProvider>
);

UserLayout.propTypes = {
  children: PropTypes.node,
  alert: PropTypes.string,
  notice: PropTypes.string,
};

UserLayout.defaultProps = {
  children: null,
  alert: null,
  notice: null,
};

export default UserLayout;
