import moment from 'moment';

const displayDateTime = (strDatetime, useAgo = true) => {
  const target = moment(strDatetime);

  if (!useAgo) {
    return target.format('YYYY年M月D日 k:mm');
  }

  const now = moment();

  const days = now.diff(target, 'days');
  if (days < 0 || days > 2) {
    return target.format('YYYY年M月D日 k:mm');
  }
  if (days >= 1) {
    return `${days}日前`;
  }

  const hours = now.diff(target, 'hours');
  if (hours >= 1) {
    return `${hours}時間前`;
  }

  const minutes = now.diff(target, 'minutes');
  if (minutes >= 1) {
    return `${minutes}分前`;
  }

  return `${now.diff(target, 'seconds')}秒前`;
};

export default displayDateTime;
