import { scroller } from 'react-scroll';

const scrollTo = (elementId) => {
  scroller.scrollTo(elementId, {
    duration: 1500,
    delay: 50,
    smooth: true,
    offset: -70,
    container: document.body,
  });
};

export default scrollTo;
