import React from 'react';
import { AdPropType } from '../constants/PropTypes';

function onload(adId) {
  if (global.PeerRing.RailsEnv === 'production') {
    global.ga('send', 'event', 'ad', 'impression', adId);
  }
}

class ImpressionTag extends React.Component {
  constructor(props) {
    super(props);
    this.date = Date.now();
  }

  render() {
    const { ad } = this.props;
    if (!ad) {
      return null;
    }
    return <img width="1" height="1" src={`/1x1.gif?${this.date}`} onLoad={() => onload(ad.id)} alt="" />;
  }
}

ImpressionTag.propTypes = {
  ad: AdPropType,
};

ImpressionTag.defaultProps = {
  ad: null,
};

export default ImpressionTag;
