import React from 'react';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import { SMALL } from 'material-ui/utils/withWidth';
import merge from 'lodash/merge';
import Img from 'common/components/Img';
import theme from '../../constants/theme';

const getStyles = (width) => {
  let styles = {
    button: {
      height: 80,
      width: '100%',
      fontSize: 14,
    },
    container: {
      display: 'flex',
      height: 80,
    },
    textContainer: {
      flex: 1,
      padding: 10,
      lineHeight: 'normal',
      color: theme.palette.textColor,
      textAlign: 'left',
    },
    text: {
      overflowY: 'hidden',
      height: '100%',
    },
    title: {
      marginBottom: 5,
      fontSize: '1em',
    },
    imageContiner: {
      width: 80,
      overflowY: 'hidden',
      position: 'relative',
    },
    img: {
      position: 'absolute',
      width: '100%',
      height: 'auto',
      display: 'block',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      margin: 'auto',
    },
  };
  const midStyles = {
    button: {
      height: 125,
    },
    container: {
      height: 125,
    },
    title: {
      fontSize: '1.1em',
      lineHeight: '1.4em',
    },
    imageContiner: {
      width: 125,
    },
    textContainer: {
    },
  };
  if (width > SMALL) {
    styles = merge(styles, midStyles);
  }
  return styles;
};

const renderImage = (article, styles) => {
  if (!article.featuredmedia || !article.featuredmedia.url) {
    return null;
  }

  const imgStyle = styles.img;
  if (article.featuredmedia.width > article.featuredmedia.height) {
    imgStyle.height = '100%';
    imgStyle.width = 'auto';
  }

  const src = article.featuredmedia.sizes.large ?
    article.featuredmedia.sizes.large.source_url : article.featuredmedia.url;

  return (
    <div style={styles.imageContiner}>
      <Img
        style={imgStyle}
        src={src}
        alt={article.title}
        w={styles.imageContiner.width}
        h={styles.button.height}
        crop
      />
    </div>
  );
};

/* eslint-disable react/no-danger */
const ArticlesListItem = (props) => {
  const { article, width } = props;
  const styles = getStyles(width);

  let excerpt = null;
  if (width > SMALL) {
    excerpt = <div dangerouslySetInnerHTML={{ __html: article.excerpt }} />;
  }

  return (
    <RaisedButton
      style={styles.button}
      href={`/articles/${article.id}`}
      target="articles"
    >
      <div style={styles.container}>
        <div style={styles.textContainer}>
          <div style={styles.text}>
            <h3 style={styles.title}>{article.title}</h3>
            {excerpt}
          </div>
        </div>
        {renderImage(article, styles)}
      </div>
    </RaisedButton>
  );
};
/* eslint-enable */

ArticlesListItem.propTypes = {
  article: PropTypes.shape().isRequired,
  width: PropTypes.number.isRequired,
};

export default ArticlesListItem;
