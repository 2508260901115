import React from 'react';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import { red200 } from 'material-ui/styles/colors';
import { PostPropType, AdsPropType } from 'common/constants/PropTypes';
import PostViewContainer from '../containers/PostViewContainer';

const styles = {
  container: {
    margin: '0 auto',
  },
  error: {
    textAlign: 'center',
    marginTop: 50,
    fontSize: 20,
    color: red200,
  },
  postContainer: {
    marginBottom: 5,
  },
};

class PostsList extends React.Component {
  renderPaginateButton() {
    const { page, totalPage, isFetching, handlePaginateButton } = this.props;
    if (page >= totalPage || isFetching) {
      return null;
    }
    return (
      <RaisedButton
        label="もっと読む"
        fullWidth
        onClick={handlePaginateButton}
      />
    );
  }

  renderRefreshing() {
    if (!this.props.isRefreshing) {
      return null;
    }
    return (
      <div style={{ textAlign: 'center' }}>
        <CircularProgress size={80} />
      </div>
    );
  }

  renderFetching() {
    if (!this.props.isFetching) {
      return null;
    }
    return (
      <div style={{ textAlign: 'center' }}>
        <CircularProgress size={80} />
      </div>
    );
  }

  renderEmpty() {
    if (this.props.isFetching || this.props.posts.length > 0) {
      return null;
    }

    return (
      <div style={styles.error}>
        <div>
          <img
            src={global.PeerRing.images.logo_icon120}
            srcSet={`${global.PeerRing.images.logo_icon120_2x} 2x`}
            width={120}
            height={120}
            alt="Peer Ring Bleu"
          />
        </div>
        <div>まだ投稿がありません</div>
      </div>
    );
  }

  renderPostList() {
    const { posts, ads } = this.props;
    const postList = posts.map(post => (
      <div
        key={post.id}
        style={styles.postContainer}
      >
        <PostViewContainer post={post} />
      </div>
    ));
    if (ads) {
      Object.keys(ads).forEach((p) => {
        const post = ads[p].post;
        postList.splice(
          p - 1,
          0,
          <div
            key={post.id}
            style={styles.postContainer}
          >
            <PostViewContainer post={post} ad={ads[p]} />
          </div>,
        );
      });
    }
    return <div>{postList}</div>;
  }

  render() {
    return (
      <div style={styles.container}>
        {this.renderEmpty()}
        {this.renderRefreshing()}
        {this.renderPostList()}
        {this.renderPaginateButton()}
        {this.renderFetching()}
      </div>
    );
  }
}

PostsList.propTypes = {
  posts: PropTypes.arrayOf(PostPropType).isRequired,
  isFetching: PropTypes.bool.isRequired,
  isRefreshing: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  handlePaginateButton: PropTypes.func.isRequired,
  ads: AdsPropType.isRequired,
};

export default PostsList;
