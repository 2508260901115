const createNotificationHref = (notification) => {
  let href;
  switch (notification.notificationable.type) {
    case 'Comment':
      href = `/posts/${notification.notificationable.commentable.id}`;
      break;
    case 'Like':
      if (notification.notificationable.likeable.type === 'Post') {
        href = `/posts/${notification.notificationable.likeable.id}`;
      } else if (notification.notificationable.likeable.type === 'Comment') {
        href = `/posts/${notification.notificationable.likeable.commentable.id}`;
      } else {
        return null;
      }
      break;
    case 'Mention':
      if (notification.notificationable.mentionable.type === 'Post') {
        href = `/posts/${notification.notificationable.mentionable.id}`;
      } else if (notification.notificationable.mentionable.type === 'Comment') {
        href = `/posts/${notification.notificationable.mentionable.commentable.id}`;
      } else {
        return null;
      }
      break;
    default:
      return null;
  }
  return href;
};

export default createNotificationHref;
