import React from 'react';
import FontIcon from 'material-ui/FontIcon';
import { PostPropType } from 'common/constants/PropTypes';
import PostViewContainer from '../containers/PostViewContainer';

const renderBackLink = () => {
  const cache = global.Turbolinks.controller.cache.keys;
  if (cache.length === 0) {
    return null;
  }
  if (cache[0] === location.href && cache.length === 1) {
    return null;
  }
  return (
    <span // eslint-disable-line jsx-a11y/no-static-element-interactions
      style={{ position: 'relative', display: 'inline-block' }}
      onClick={() => { history.back(); }}
    >
      <FontIcon className="fa fa-caret-left" />
      <span
        className="hover-underline"
        style={{ position: 'absolute', top: 0, whiteSpace: 'nowrap', marginLeft: 3 }}
      >
        戻る
      </span>
    </span>
  );
};

const PostPage = props => (
  <div>
    {renderBackLink()}
    <PostViewContainer post={props.post} showComments showAllImages contentExpand />
  </div>
);

PostPage.propTypes = {
  post: PostPropType.isRequired,
};

export default PostPage;
