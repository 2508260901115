import { handleActions } from 'redux-actions';
import { ActionTypes } from '../constants';

const postTypeString = (postType) => {
  switch (postType) {
    case 'Question':
      return '質問';
    case 'Event':
      return 'イベント';
    default:
      return 'ダイアリー';
  }
};

const messages = handleActions({
  [ActionTypes.SUBMIT_POST.SUCCESS]: (state, action) => {
    const postType = postTypeString(action.meta.postType);
    const message = `${postType}の投稿が完了しました。`;
    return [].concat(state).concat([{ message }]);
  },
  [ActionTypes.SUBMIT_POST.FAILURE]: (state, action) => {
    const postType = postTypeString(action.meta.postType);
    const message = `${postType}の投稿でエラーが発生しました。`;
    return [].concat(state).concat([{ message }]);
  },
  [ActionTypes.UPDATE_POST.SUCCESS]: (state, action) => {
    const postType = postTypeString(action.meta.postType);
    const message = `${postType}の更新が完了しました。`;
    return [].concat(state).concat([{ message }]);
  },
  [ActionTypes.UPDATE_POST.FAILURE]: (state, action) => {
    const postType = postTypeString(action.meta.postType);
    const message = `${postType}の更新でエラーが発生しました。`;
    return [].concat(state).concat([{ message }]);
  },
  [ActionTypes.FETCH_POSTS.FAILURE]: (state) => {
    const message = 'データの読み込みででエラーが発生しました。';
    return [].concat(state).concat([{ message }]);
  },
  [ActionTypes.DESTROY_POST.SUCCESS]: (state) => {
    const message = '削除しました。';
    return [].concat(state).concat([{ message }]);
  },
  [ActionTypes.DESTROY_POST.FAILURE]: (state) => {
    const message = 'エラーが発生しました。';
    return [].concat(state).concat([{ message }]);
  },
  [ActionTypes.SUBMIT_COMMENT.FAILURE]: (state) => {
    const message = 'エラーが発生しました。';
    return [].concat(state).concat([{ message }]);
  },
  [ActionTypes.DESTROY_COMMENT.SUCCESS]: (state) => {
    const message = '削除しました。';
    return [].concat(state).concat([{ message }]);
  },
  [ActionTypes.UPDATE_COMMENT.SUCCESS]: (state) => {
    const message = '更新しました。';
    return [].concat(state).concat([{ message }]);
  },
  [ActionTypes.CREATE_REPORT.SUCCESS]: (state) => {
    const message = '通報しました。';
    return [].concat(state).concat([{ message }]);
  },
  [ActionTypes.BLOCK_USER.SUCCESS]: (state) => {
    const message = 'ブロックしました。';
    return [].concat(state).concat([{ message }]);
  },
  [ActionTypes.DESTROY_COMMENT.FAILURE]: (state) => {
    const message = 'エラーが発生しました。';
    return [].concat(state).concat([{ message }]);
  },
  [ActionTypes.UPDATE_COMMENT.FAILURE]: (state) => {
    const message = 'エラーが発生しました。';
    return [].concat(state).concat([{ message }]);
  },
  [ActionTypes.LIKE_POST.FAILURE]: (state) => {
    const message = 'エラーが発生しました。';
    return [].concat(state).concat([{ message }]);
  },
  [ActionTypes.LIKE_COMMENT.FAILURE]: (state) => {
    const message = 'エラーが発生しました。';
    return [].concat(state).concat([{ message }]);
  },
  [ActionTypes.FAVORITE_POST.FAILURE]: (state) => {
    const message = 'エラーが発生しました。';
    return [].concat(state).concat([{ message }]);
  },
  [ActionTypes.CREATE_REPORT.FAILURE]: (state) => {
    const message = 'エラーが発生しました。';
    return [].concat(state).concat([{ message }]);
  },
  [ActionTypes.BLOCK_USER.FAILURE]: (state) => {
    const message = 'エラーが発生しました。';
    return [].concat(state).concat([{ message }]);
  },
  [ActionTypes.ADD_MESSAGE]: (state, action) => (
    [].concat(state).concat([{ message: action.payload }])
  ),
  [ActionTypes.REMOVE_MESSAGE]: (state, action) => (
    state.reduce((p, c) => (
      c.message === action.payload ? p : p.concat(c)
    ), [])
  ),
}, []);

export default messages;
