import React from 'react';
import PropTypes from 'prop-types';
import { SMALL } from 'material-ui/utils/withWidth';
import ArticlesListContainer from '../containers/ArticlesListContainer';

const ArticleListPage = props => (
  <div>
    <ArticlesListContainer width={props.width} />
  </div>
);

ArticleListPage.propTypes = {
  width: PropTypes.number,
};

ArticleListPage.defaultProps = {
  width: SMALL,
};

export default ArticleListPage;
