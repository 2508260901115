import {
  blue300, blue700,
  amberA200,
  grey100, grey300, grey400, grey500,
  white, lightBlack, darkBlack, fullBlack,
} from 'material-ui/styles/colors';
import { fade } from 'material-ui/utils/colorManipulator';
import spacing from 'material-ui/styles/spacing';

export default {
  spacing,
  fontFamily: '-apple-system, BlinkMacSystemFont, "Helvetica Neue", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Arial, "メイリオ", "Meiryo", sans-serif',
  palette: {
    primary1Color: blue300,
    primary2Color: blue700,
    primary3Color: grey400,
    accent1Color: amberA200,
    accent2Color: grey100,
    accent3Color: grey500,
    textColor: darkBlack,
    secondaryTextColor: fade(darkBlack, 0.54),
    alternateTextColor: white,
    canvasColor: white,
    borderColor: grey300,
    disabledColor: fade(darkBlack, 0.3),
    pickerHeaderColor: blue300,
    clockCircleColor: fade(darkBlack, 0.07),
    shadowColor: fullBlack,
  },
  appBar: {
    color: white,
    textColor: darkBlack,
  },
  floatingActionButton: {
    secondaryIconColor: lightBlack,
  },
  inkBar: {
    backgroundColor: blue300,
  },
  tabs: {
    backgroundColor: white,
    selectedTextColor: blue300,
    textColor: grey400,
  },
};
