import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import EventListener from 'react-event-listener';

const styles = {
  img: {
    width: '100%',
  },
};

class ImageButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 'auto',
    };
    this.image = null;
    this.handleImageOnLoad = this.handleImageOnLoad.bind(this);
  }

  handleImageOnLoad() {
    const height = this.image.offsetHeight || 'auto';
    this.setState({ height });
  }

  render() {
    const { href, src, alt, onClick, style } = this.props;
    return (
      <div style={style}>
        <EventListener
          target="window"
          onResize={this.handleImageOnLoad}
        />
        <FlatButton style={{ height: this.state.height }} href={href} onClick={onClick}>
          <img
            ref={(i) => { this.image = i; }}
            style={styles.img}
            src={src}
            alt={alt}
            onLoad={this.handleImageOnLoad}
          />
        </FlatButton>
      </div>
    );
  }
}

ImageButton.propTypes = {
  href: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.shape({}),
};

ImageButton.defaultProps = {
  href: undefined,
  onClick: undefined,
  style: {},
};

export default ImageButton;
