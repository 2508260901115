import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ImageButton from '../../../../common/components/ImageButton';

const styles = {
  container: {
    marginBottom: 10,
  },
};

const HomeTopImage = (props, { user }) => (
  user ? null : (
    <div style={styles.container}>
      <ImageButton
        href="/about"
        src={global.PeerRing.images.topImage}
        alt="共感と支え合いの輪に参加しませんか？"
      />
    </div>
  )
);

HomeTopImage.contextTypes = {
  user: PropTypes.shape(),
};

export default connect()(HomeTopImage);
