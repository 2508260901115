import React from 'react';
import PropTypes from 'prop-types';
import PostsListContainer from '../containers/PostsListContainer';
import HomeTopImage from './home/HomeTopImage';

const styles = {
  countContainer: {
    marginBottom: 10,
    textAlign: 'right',
    paddingRight: 10,
    fontSize: 13,
  },
};

const renderCounts = (postCount, commentCount, userCount) => (
  <div style={styles.countContainer}>
    投稿数：{(postCount + commentCount).toLocaleString()}件、会員数：{userCount.toLocaleString()}人
  </div>
);

const Home = ({ postCount, commentCount, userCount }) => (
  <div>
    <HomeTopImage />
    {renderCounts(postCount, commentCount, userCount)}
    <PostsListContainer
      paginationKey="HOME"
      searchParam={null}
    />
  </div>
);

Home.propTypes = {
  postCount: PropTypes.number.isRequired,
  commentCount: PropTypes.number.isRequired,
  userCount: PropTypes.number.isRequired,
};

export default Home;
