import React from 'react';
import PropTypes from 'prop-types';
import Drawer from 'material-ui/Drawer';
import { List, ListItem, makeSelectable } from 'material-ui/List';
import Subheader from 'material-ui/Subheader';
import Divider from 'material-ui/Divider';
import { spacing } from 'material-ui/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { SMALL } from 'material-ui/utils/withWidth';
import { UserPropType } from 'common/constants/PropTypes';

const SelectableList = makeSelectable(List);

const getStyles = (props) => {
  const styles = {
    logo: {
      cursor: 'pointer',
      paddingLeft: spacing.desktopGutter,
      marginBottom: 6,
    },
    logoLink: {
      color: 'inherit',
      textDecoration: 'none',
    },
    logoImg: {
      marginTop: 8,
    },
    subheader: {
      color: props.muiTheme.palette.primary1Color,
    },
  };

  if (props.width === SMALL) {
    styles.logo.marginBottom = 2;
    styles.logoImg.marginTop = 4;
  }

  return styles;
};

const shuffle = () => Math.random() - 0.5;
const renderKeywords = keywords => keywords.sort(shuffle).slice(0, 5).map(t => (
  <ListItem
    key={t}
    primaryText={`${t}`}
    value={`/search/${encodeURIComponent(t)}`}
    href={`/search/${encodeURIComponent(t)}`}
  />
));

const renderLinks = () => {
  const links = [
    {
      href: '/terms',
      name: '利用規約',
    },
    {
      href: '/privacy',
      name: 'プライバシーポリシー',
    },
    {
      href: '/guideline',
      name: '利用に関する注意点',
    },
    {
      href: 'https://site.peer-ring.com/',
      name: '運営協力',
      target: '_blank',
    },
    {
      href: 'https://risa-sana.co/',
      name: '運営会社',
      target: '_blank',
    },
    {
      href: '/inquiries',
      name: 'お問い合わせ',
    },
    {
      href: 'https://news.peer-ring.com/7671/',
      name: '顧問医師',
      target: '_blank',
    },
    {
      href: 'https://news.peer-ring.com/7693/',
      name: '使い方・FAQ',
      target: '_blank',
    },
  ];
  const styles = {
    links: {
      padding: 16,
    },
    linksLi: {
      display: 'inline-block',
      fontSize: 14,
      paddingRight: 10,
    },
    linksA: {
      color: 'inherit',
    },
  };

  return (
    <div style={styles.links}>
      <div>&copy; Risa Sana, Inc</div>
      <ul>
        {links.map(l => (
          <li key={l.href} style={styles.linksLi}>
            <a
              className="hover-underline"
              style={styles.linksA}
              href={l.href}
              target={l.target}
            >
              {l.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

const AppNavDrawer = (props, context) => {
  const {
    docked,
    open,
    style,
    onRequestChangeNavDrawer,
    keywords,
  } = props;

  const { user } = context;

  const styles = getStyles(props);
  return (
    <Drawer
      style={style}
      docked={docked}
      open={open}
      onRequestChange={() => { onRequestChangeNavDrawer(false); }}
    >
      <div style={styles.logo}>
        <a style={styles.logoLink} href="/">
          <img
            src={global.PeerRing.images.logo_title}
            srcSet={`${global.PeerRing.images.logo_title_2x} 2x`}
            style={styles.logoImg}
            alt="Peer Ring Bleu"
          />
        </a>
      </div>
      <Divider />
      <SelectableList
        value={location.pathname}
      >
        <ListItem
          primaryText="はじめて利用する方へ"
          value="/about"
          href="/about"
        />
        <Divider />
        <Subheader style={styles.subheader}>メニュー</Subheader>
        <ListItem
          primaryText="新着"
          value="/"
          href="/"
        />
        <ListItem
          primaryText="ダイアリー"
          value="/diaries"
          href="/diaries"
        />
        <ListItem
          primaryText="質問"
          value="/questions"
          href="/questions"
        />
        <ListItem
          primaryText="イベント"
          value="/events"
          href="/events"
        />
        <ListItem
          primaryText="使い方"
          value="/recommends"
          href="/recommends"
        />
        {user && (
          <ListItem
            primaryText="お気に入り"
            value="/favorites"
            href="/favorites"
          />
        )}
        <ListItem
          primaryText="記事"
          value="/articles"
          href="/articles"
        />
        <ListItem
          primaryText="仲間を探す"
          value="/users"
          href="/users"
        />
        <Divider />
        <Subheader style={styles.subheader}>人気の検索ワード</Subheader>
        {renderKeywords(keywords)}
      </SelectableList>
      <Divider />
      <Subheader style={styles.subheader}>がん治療に役立つ食事の工夫</Subheader>
      <List>
        <ListItem
          primaryText="kama+aid"
          href="https://kama-aid.com/"
          target="_blank"
        />
      </List>
      <Divider />
      <Subheader style={styles.subheader}>がん患者さん用ケア用品</Subheader>
      <List>
        <ListItem
          primaryText="Peer Ring 'toi'"
          href="https://peerring.thebase.in/"
          target="_blank"
        />
      </List>
      <Divider />
      {renderLinks()}
    </Drawer>
  );
};

AppNavDrawer.propTypes = {
  docked: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  style: PropTypes.shape(),
  onRequestChangeNavDrawer: PropTypes.func.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string),
};

AppNavDrawer.defaultProps = {
  style: {},
  keywords: [],
};

AppNavDrawer.contextTypes = {
  user: UserPropType,
};

export default muiThemeable()(AppNavDrawer);
