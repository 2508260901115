import React from 'react';
import PropTypes from 'prop-types';
import AppBar from 'material-ui/AppBar';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import FlatButton from 'material-ui/FlatButton';
import IconButton from 'material-ui/IconButton';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import Popover from 'material-ui/Popover';
import Badge from 'material-ui/Badge';
import AutoComplete from 'material-ui/AutoComplete';
import Divider from 'material-ui/Divider';
import ActionSearch from 'material-ui/svg-icons/action/search';
import ContentClear from 'material-ui/svg-icons/content/clear';
import SocialNotifications from 'material-ui/svg-icons/social/notifications';
import SocialPeople from 'material-ui/svg-icons/social/people';
import FontIcon from 'material-ui/FontIcon';
import { SMALL } from 'material-ui/utils/withWidth';
import { UserPropType, NotificationPropType } from 'common/constants/PropTypes';
import NotificationsMenu from './NotificationsMenu';
import UserAvatar from '../utils/UserAvatar';
import createHeaders from '../../utils/createHeaders';

const USER_SEARCH_TEXT = '          ';

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationsMenuOpen: false,
      userMenuOpen: false,
      userMenuAnchorEl: null,
      notificationsCount: props.user ? props.user.notifications_count : 0,
      searchBarOpen: false,
      searchValue: '',
    };
    this.handleUserMenuRequestClose = this.handleUserMenuRequestClose.bind(this);
    this.handleTouchTapUserIcon = this.handleTouchTapUserIcon.bind(this);
    this.handleTouchTapNotificationsIcon = this.handleTouchTapNotificationsIcon.bind(this);
    this.handleNotificationsMenuRequestClose = this.handleNotificationsMenuRequestClose.bind(this);
    this.handleSeachButton = this.handleSeachButton.bind(this);
    this.handleCloseSearchButton = this.handleCloseSearchButton.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
    // this.handleKeyupSearch = this.handleKeyupSearch.bind(this);
    // this.handleKeypressSearch = this.handleKeypressSearch.bind(this);
    this.handleUserSearch = this.handleUserSearch.bind(this);
  }

  getStyles() {
    const { muiTheme, width } = this.props;

    const styles = {
      appBar: {
        position: 'fixed',
        zIndex: muiTheme.zIndex.appBar + 1,
        top: 0,
      },
      appBarTitle: {
        cursor: 'pointer',
        position: 'relative',
      },
      appBarTitleImg: {
        marginTop: 8,
        marginRight: 8,
        maxWidth: '100%',
      },
      actionButton: {
        position: 'fixed',
        zIndex: muiTheme.zIndex.appBar + 2,
        right: 16,
        bottom: 16,
      },
      appBarButton: {
        color: muiTheme.appBar.textColor,
      },
      appBarIconButton: {
        color: muiTheme.appBar.textColor,
        verticalAlign: 'middle',
      },
      searchBar: {
        backgroundColor: muiTheme.appBar.color,
        zIndex: muiTheme.zIndex.appBar + 2,
      },
      iconStyleLeft: {},
      iconStyleRight: {},
    };

    if (width === SMALL) {
      styles.appBarTitle.height = 56;
      styles.appBarTitle.lineHeight = '56px';
      styles.appBarTitle.textAlign = 'center';
      styles.appBarTitleImg.marginTop = 0;
      styles.appBarTitleImg.marginRight = 0;
      styles.appBarTitleImg.position = 'absolute';
      styles.appBarTitleImg.top = '50%';
      styles.appBarTitleImg.WebkitTransform = 'translate3d(-50%, -50%, 0)';
      styles.appBarTitleImg.transform = 'translate3d(-50%, -50%, 0)';
      styles.iconStyleLeft.marginTop = 4;
      styles.iconStyleRight.marginTop = 4;
    }
    return styles;
  }

  getDataSource() {
    const dataSource = this.props.keywords.map(t => ({ text: t, value: <MenuItem primaryText={`${t}`} /> }));
    const userSearchSource = [
      {
        text: USER_SEARCH_TEXT,
        value: (
          <MenuItem
            primaryText="仲間を探す（人を検索）"
            rightIcon={<SocialPeople />}
            onClick={this.handleUserSearch}
          />
        ),
      },
      { text: USER_SEARCH_TEXT, value: <Divider /> },
    ];
    return userSearchSource.concat(dataSource);
  }

  gotoLink(link) {
    this.setState({ userMenuOpen: false }, () => {
      // Popoverは500ms後にunmount処理が走るので少し待つ
      setTimeout(() => global.Turbolinks.visit(link), 501);
    });
  }

  handleUserMenuRequestClose() {
    this.setState({ userMenuOpen: false });
  }

  handleTouchTapUserIcon(event) {
    event.preventDefault();
    this.setState({
      userMenuOpen: !this.state.userMenuOpen,
      userMenuAnchorEl: event.currentTarget,
    });
  }

  handleTouchTapNotificationsIcon(event) {
    event.preventDefault();
    if (this.state.notificationsCount > 0) {
      const body = { user: { notifications_count: 0 } };
      fetch(`/api/users/${this.props.user.login}.json`, {
        method: 'PATCH',
        headers: createHeaders(this.props.user),
        body: JSON.stringify(body),
      });
    }
    this.setState({
      notificationsMenuOpen: !this.state.notificationsMenuOpen,
      notificationsMenuAnchorEl: event.currentTarget,
      notificationsCount: 0,
    });
  }

  handleNotificationsMenuRequestClose(reason, link = null) {
    let callback = null;
    if (link) {
      callback = () => {
        // // Popoverは500ms後にunmount処理が走るので少し待つ
        setTimeout(() => global.Turbolinks.visit(link), 501);
      };
    }
    this.setState({ notificationsMenuOpen: false }, callback);
  }

  handleSeachButton() {
    this.setState({ searchBarOpen: true });
  }

  handleCloseSearchButton() {
    this.setState({ searchBarOpen: false });
  }

  handleSubmitSearch() {
    const { searchValue } = this.state;
    if (searchValue === USER_SEARCH_TEXT) {
      return;
    }
    if (searchValue && searchValue.trim().length > 0) {
      global.Turbolinks.visit(`/search/${encodeURIComponent(searchValue)}`);
    }
  }

  handleUserSearch() {
    const { searchValue } = this.state;
    const query = searchValue && searchValue.trim().length > 0 ?
      `?q=${encodeURIComponent(searchValue)} ` : '';
    global.Turbolinks.visit(`/users${query}`);
  }

  handleChangeSearch(searchValue) {
    this.setState({ searchValue });
  }

  /*
  handleKeypressSearch() {
    // IME変換中はKeyPessイベントが呼ばれない
    this.setState({ keypress: true });
  }

  handleKeyupSearch(event) {
    if (this.state.keypress && event.keyCode === 13) {
      this.handleSubmitSearch();
    }
    this.setState({ keypress: false });
  }
  */

  renderAppBarIcon() {
    if (this.state.searchBarOpen) {
      return (
        <div>
          {this.renderSearchBar()}
        </div>
      );
    }
    return (
      <div>
        {this.renderAppBarActionButton()}
        {this.renderUserIcon()}
        {this.renderSearchButton()}
      </div>
    );
  }

  renderSearchBar() {
    const styles = this.getStyles();
    const iconStyle = {
      color: styles.appBarButton.color,
    };

    return (
      <div
        style={Object.assign({ marginTop: -6, position: 'absolute', right: 16 }, styles.searchBar)}
      >
        <IconButton
          iconStyle={iconStyle}
          style={{ top: 8 }}
          onClick={this.handleSubmitSearch}
        >
          <ActionSearch />
        </IconButton>
        <AutoComplete
          dataSource={this.getDataSource()}
          hintText="フリーワード検索"
          inputStyle={{ color: styles.appBarButton.color }}
          underlineStyle={{ borderColor: styles.appBarButton.color, borderWidth: 0.5 }}
          underlineFocusStyle={{ borderColor: styles.appBarButton.color, borderWidth: 2 }}
          // searchText={this.state.searchValue}
          onUpdateInput={this.handleChangeSearch}
          // onKeyUp={this.handleKeyupSearch}
          // onKeyPress={this.handleKeypressSearch}
          style={{ width: 200 }}
          textFieldStyle={{ width: 200 }}
          onNewRequest={this.handleSubmitSearch}
          filter={(searchText, key) => (
            key === USER_SEARCH_TEXT || key.indexOf(searchText) !== -1
          )}
          maxSearchResults={8}
          openOnFocus
        />
        <IconButton
          iconStyle={iconStyle}
          style={{ top: 8 }}
          onClick={this.handleCloseSearchButton}
        >
          <ContentClear />
        </IconButton>
      </div>
    );
  }

  renderUserMenu() {
    const { user } = this.props;
    if (!user) {
      return null;
    }

    return (
      <Popover
        open={this.state.userMenuOpen}
        anchorEl={this.state.userMenuAnchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
        onRequestClose={this.handleUserMenuRequestClose}
      >
        <Menu>
          <MenuItem
            primaryText="マイページ"
            onClick={() => this.gotoLink(`/users/${user.login}`)}
          />
          <MenuItem
            primaryText="プロフィール編集"
            onClick={() => this.gotoLink('/users/edit')}
          />
          <MenuItem
            primaryText="ログアウト"
            href="/users/sign_out"
            data-method="delete"
            data-confirm="本当にログアウトしますか？"
          />
        </Menu>
      </Popover>
    );
  }

  renderAppBarActionButton() {
    const { width, handleActionButton } = this.props;
    const styles = this.getStyles();
    if (width === SMALL) {
      return null;
    }

    return (
      <FlatButton
        label="ダイアリー・質問を書く"
        icon={<FontIcon className="fa fa-pencil-square-o" />}
        onClick={handleActionButton}
        style={styles.appBarButton}

      />
    );
  }

  renderUserIcon() {
    const { user, width } = this.props;
    const styles = this.getStyles();

    if (!user) {
      if (width === SMALL) {
        return null;
      }
      return ([
        <FlatButton
          key="ログイン"
          label="ログイン"
          href="/users/sign_in"
          style={styles.appBarButton}
        />,
        <FlatButton
          key="会員登録"
          label="会員登録"
          href="/users/sign_up"
          style={styles.appBarButton}
        />,
      ]);
    }

    const badgeStyle = (this.state.notificationsCount === 0) ? { display: 'none' } : null;
    const badgeContent = (user.notifications_count > 10) ? '10+' : user.notifications_count;

    if (width === SMALL) {
      return (
        <span>
          <Badge
            style={{ padding: 0, paddingBottom: 4 }}
            badgeContent={badgeContent}
            badgeStyle={badgeStyle}
            primary
          >
            <IconButton
              onClick={this.handleTouchTapNotificationsIcon}
              iconStyle={{ color: styles.appBarButton.color }}
              style={styles.appBarIconButton}
            >
              <SocialNotifications />
            </IconButton>
          </Badge>
          <IconButton
            onClick={this.handleTouchTapUserIcon}
            style={styles.appBarIconButton}
          >
            <UserAvatar user={user} size={24} />
          </IconButton>
        </span>
      );
    }

    return (
      <span>
        <Badge
          style={{ padding: 0 }}
          badgeContent={badgeContent}
          badgeStyle={badgeStyle}
          primary
        >
          <FlatButton
            label="お知らせ"
            icon={<SocialNotifications />}
            onClick={this.handleTouchTapNotificationsIcon}
            style={styles.appBarButton}
          />
        </Badge>
        <FlatButton
          label={user.name}
          icon={<UserAvatar user={user} size={24} />}
          onClick={this.handleTouchTapUserIcon}
          style={styles.appBarButton}
        />
      </span>
    );
  }

  renderSearchButton() {
    const styles = this.getStyles();

    return (
      <IconButton
        iconStyle={{ color: styles.appBarButton.color }}
        style={styles.appBarIconButton}
        onClick={this.handleSeachButton}
      >
        <ActionSearch />
      </IconButton>
    );
  }

  renderFloatingActionButton() {
    const { width, handleActionButton, showPostActionButton } = this.props;
    if (!showPostActionButton) {
      return null;
    }
    const styles = this.getStyles();
    if (width > SMALL) {
      return null;
    }

    return (
      <FloatingActionButton
        style={styles.actionButton}
        onClick={handleActionButton}
        secondary
      >
        <FontIcon className="fa fa-pencil-square-o" />
      </FloatingActionButton>
    );
  }

  renderTitleImage() {
    const styles = this.getStyles();

    return (
      <img
        src={global.PeerRing.images.logo_title}
        srcSet={`${global.PeerRing.images.logo_title_2x} 2x`}
        style={styles.appBarTitleImg}
        alt="Peer Ring Bleu"
      />
    );
  }

  render() {
    if (global.PeerRing.embeded) {
      return null;
    }

    const {
      user,
      notifications,
      showMenuIconButton,
      handleTouchTapLeftIconButton,
    } = this.props;
    const styles = this.getStyles();

    return (
      <div>
        <AppBar
          style={styles.appBar}
          titleStyle={styles.appBarTitle}
          iconStyleLeft={styles.iconStyleLeft}
          iconStyleRight={styles.iconStyleRight}
          title={this.renderTitleImage()}
          onTitleClick={() => { global.Turbolinks.visit('/'); }}
          onLeftIconButtonClick={handleTouchTapLeftIconButton}
          showMenuIconButton={showMenuIconButton}
          iconElementRight={this.renderAppBarIcon()}
        />
        {this.renderUserMenu()}
        <NotificationsMenu
          user={user}
          notifications={notifications}
          open={this.state.notificationsMenuOpen}
          anchorEl={this.state.notificationsMenuAnchorEl}
          onRequestClose={this.handleNotificationsMenuRequestClose}
        />
        {this.renderFloatingActionButton()}
      </div>
    );
  }
}

NavBar.propTypes = {
  user: UserPropType,
  notifications: PropTypes.arrayOf(NotificationPropType),
  muiTheme: PropTypes.shape().isRequired,
  width: PropTypes.number,
  showMenuIconButton: PropTypes.bool,
  handleActionButton: PropTypes.func.isRequired,
  handleTouchTapLeftIconButton: PropTypes.func.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string),
  showPostActionButton: PropTypes.bool,
};

NavBar.defaultProps = {
  user: null,
  notifications: [],
  width: SMALL,
  showMenuIconButton: false,
  keywords: [],
  showPostActionButton: false,
};

export default NavBar;
