import { handleActions } from 'redux-actions';
import { ActionTypes } from '../constants';

const replacePost = (post, state) => {
  const newState = Object.assign({}, state);
  newState.posts[post.id] = post;
  return newState;
};

const replaceArticle = (article, state) => {
  const newState = Object.assign({}, state);
  newState.articles[article.id] = article;
  return newState;
};

const entities = handleActions({
  [ActionTypes.FETCH_POSTS.SUCCESS]: (state, action) => {
    let newState = action.payload.data.reduce((r, post) => replacePost(post, r), state);
    if (action.payload.ads) {
      newState =
        action.payload.ads.map(ad => ad.post).reduce((r, post) => replacePost(post, r), state);
      newState.ads = action.payload.ads.reduce((ads, ad) => {
        const newAds = Object.assign({}, ads);
        newAds[ad.priority] = ad;
        newAds[ad.priority].postId = ad.post.id;
        delete newAds[ad.priority].post;
        return newAds;
      }, {});
    }
    return newState;
  },
  [ActionTypes.FETCH_POST.SUCCESS]: (state, action) => replacePost(action.payload, state),
  [ActionTypes.SUBMIT_POST.SUCCESS]: (state, action) => replacePost(action.payload, state),
  [ActionTypes.UPDATE_POST.SUCCESS]: (state, action) => replacePost(action.payload, state),
  [ActionTypes.SUBMIT_COMMENT.SUCCESS]: (state, action) => replacePost(action.payload, state),
  [ActionTypes.DESTROY_COMMENT.SUCCESS]: (state, action) => replacePost(action.payload, state),
  [ActionTypes.UPDATE_COMMENT.SUCCESS]: (state, action) => replacePost(action.payload, state),
  [ActionTypes.LIKE_POST.SUCCESS]: (state, action) => replacePost(action.payload, state),
  [ActionTypes.UNLIKE_POST.SUCCESS]: (state, action) => replacePost(action.payload, state),
  [ActionTypes.LIKE_COMMENT.SUCCESS]: (state, action) => replacePost(action.payload, state),
  [ActionTypes.UNLIKE_COMMENT.SUCCESS]: (state, action) => replacePost(action.payload, state),
  [ActionTypes.FAVORITE_POST.SUCCESS]: (state, action) => replacePost(action.payload, state),
  [ActionTypes.UNFAVORITE_POST.SUCCESS]: (state, action) => replacePost(action.payload, state),
  [ActionTypes.DESTROY_POST.SUCCESS]: (state, action) => {
    const newState = Object.assign({}, state);
    delete newState.posts[action.meta.id];
    return newState;
  },
  [ActionTypes.FETCH_ARTICLES.SUCCESS]: (state, action) => (
    action.payload.data.reduce((r, article) => replaceArticle(article, r), state)
  ),
  [ActionTypes.BLOCK_USER.SUCCESS]: (state, action) => ({ ...state, blockUserIds: action.payload }),
}, { posts: {}, articles: {}, ads: {}, blockUserIds: [] });

export default entities;
