function createRequestTypes(base) {
  const REQUEST = 'REQUEST';
  const SUCCESS = 'SUCCESS';
  const FAILURE = 'FAILURE';

  return [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => {
    const types = acc;
    types[type] = `${base}_${type}`;
    return types;
  }, {});
}

export const FETCH_POSTS = createRequestTypes('FETCH_POSTS');
export const FETCH_POST = createRequestTypes('FETCH_POST');
export const SUBMIT_POST = createRequestTypes('SUBMIT_POST');
export const UPDATE_POST = createRequestTypes('UPDATE_POST');
export const DESTROY_POST = createRequestTypes('DESTROY_POST');
export const SUBMIT_COMMENT = createRequestTypes('SUBMIT_COMMENT');
export const DESTROY_COMMENT = createRequestTypes('DESTROY_COMMENT');
export const UPDATE_COMMENT = createRequestTypes('UPDATE_COMMENT');
export const LIKE_POST = createRequestTypes('LIKE_POST');
export const UNLIKE_POST = createRequestTypes('UNLIKE_POST');
export const LIKE_COMMENT = createRequestTypes('LIKE_COMMENT');
export const UNLIKE_COMMENT = createRequestTypes('UNLIKE_COMMENT');
export const FAVORITE_POST = createRequestTypes('FAVORITE_POST');
export const UNFAVORITE_POST = createRequestTypes('UNFAVORITE_POST');
export const FETCH_ARTICLES = createRequestTypes('FETCH_ARTICLES');
export const CREATE_REPORT = createRequestTypes('CREATE_REPORT');
export const BLOCK_USER = createRequestTypes('BLOCK_USER');

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
