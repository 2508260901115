import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import $ from 'jquery';

// 戻るボタンでスクロール位置も復元する
global.Turbolinks.controller.scrollManager.scrollToPosition = (t) => {
  setTimeout(() => global.scrollTo(t.x, t.y), 10);
};

export default function addEventListenerOnTurbolinksLoad(store, myComponents) {
  $(document).on('turbolinks:before-render', () => {
    const root = $('.react-component-layout');
    if (root.get(0)) {
      ReactDOM.unmountComponentAtNode(root.get(0));
    }
  });

  const onMount = () => {
    const childReactEl = [];
    const childEl = $('.react-component');
    if (childEl.get(0)) {
      childReactEl.push(
        React.createElement(
          myComponents[childEl.data('component')],
          childEl.data('props'),
        ),
      );
    }

    const root = $('.react-component-layout');
    if (root.get(0)) {
      const el = React.createElement(
        myComponents[root.data('component')],
        root.data('props'),
        childReactEl[0],
      );

      ReactDOM.render(<Provider store={store}>{el}</Provider>, root.get(0));
    } else if (childEl.get(0)) {
      ReactDOM.render(<Provider store={store}>{childReactEl[0]}</Provider>, childEl.get(0));
    }
  };
  $(document).one('turbolinks:load', onMount);
  $(document).on('turbolinks:render', onMount);
}
