import React from 'react';
import PropTypes from 'prop-types';
import marked from 'marked';
import Paper from 'material-ui/Paper';
import PrivacyText from '../../files/Privacy.md';
import TermsText from '../../files/Terms.md';
import GuidelineText from '../../files/Guideline.md';
import AfterSignUpText from '../../files/AfterSignUp.md';
import '../../sass/markdown.scss';
import AboutPage from './staticPages/AboutPage';

const styles = {
  container: {
    padding: 15,
    fontSize: 14,
  },
  aboutImage: {
    width: '100%',
    height: 'auto',
    marginBottom: 10,
  },
};

const StaticPage = (props) => {
  let text = null;
  switch (props.page) {
    case 'privacy':
      text = PrivacyText;
      break;
    case 'terms':
      text = TermsText;
      break;
    case 'guideline':
      text = GuidelineText;
      break;
    case 'about':
      return <AboutPage />;
    case 'after_sign_up':
      text = AfterSignUpText;
      break;
    default:
      return <div />;
  }

  marked.setOptions({
    gfm: true,
    tables: true,
    breaks: true,
    pedantic: true,
    sanitize: false,
    smartLists: true,
    smartypants: true,
  });

  return (
    <Paper style={styles.container}>
      <div
        className="markdown-body"
        /* eslint-disable react/no-danger */
        dangerouslySetInnerHTML={{ __html: marked(text) }}
        /* eslint-enable */
      />
    </Paper>
  );
};

StaticPage.propTypes = {
  page: PropTypes.string.isRequired,
};

export default StaticPage;
