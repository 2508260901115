import React from 'react';
import PropTypes from 'prop-types';

const Rectangle = (props) => {
  const { children, style, aspectRatio } = props;
  return (
    <div style={{ position: 'relative', overflow: 'hidden', ...style }}>
      <div style={{ display: 'block', paddingTop: `${100 / aspectRatio}%` }} />
      <div style={{ position: 'absolute', bottom: 0, left: 0, top: 0, right: 0 }}>
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>{children}</div>
      </div>
    </div>
  );
};

Rectangle.propTypes = {
  children: PropTypes.node,
  style: PropTypes.shape(),
  aspectRatio: PropTypes.number,
};

Rectangle.defaultProps = {
  children: null,
  style: {},
  aspectRatio: 1,
};

export default Rectangle;
