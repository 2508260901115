import React from 'react';
import PropTypes from 'prop-types';
import convertImgSrc from '../utils/convertImgSrc';

class Img extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  render() {
    const { src, alt, w, h, crop, ...other } = this.props;
    let imgSrc = src;
    let handleError = null;
    if (!this.state.error) {
      imgSrc = convertImgSrc(src, w, h, crop);
      handleError = () => { this.setState({ error: true }); };
    }
    return (
      <img
        src={imgSrc}
        alt={alt}
        {...other}
        onError={handleError}
      />
    );
  }
}

Img.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  w: PropTypes.number,
  h: PropTypes.number,
  crop: PropTypes.bool,
};

Img.defaultProps = {
  w: 748,
  h: 1200,
  crop: false,
};

export default Img;
