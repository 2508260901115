import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PostPropType } from 'common/constants/PropTypes';
import PostView from '../posts/PostView';
import {
  updatePost,
  likePost,
  unlikePost,
  submitComment,
  destroyComment,
  updateComment,
  likeComment,
  unlikeComment,
  createReport,
  blockUser,
  favoritePost,
  unfavoritePost,
} from '../../actionCreators';

const PostViewContainer = props => props.post && <PostView {...props} />;

PostViewContainer.propTypes = {
  post: PostPropType.isRequired,
  updatePost: PropTypes.func.isRequired,
  likePost: PropTypes.func.isRequired,
  unlikePost: PropTypes.func.isRequired,
  submitComment: PropTypes.func.isRequired,
  destroyComment: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  createReport: PropTypes.func.isRequired,
  blockUser: PropTypes.func.isRequired,
  showComments: PropTypes.bool,
  likeComment: PropTypes.func.isRequired,
  unlikeComment: PropTypes.func.isRequired,
  favoritePost: PropTypes.func.isRequired,
  unfavoritePost: PropTypes.func.isRequired,
  blockUserIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

PostViewContainer.defaultProps = {
  showComments: false,
};

const mapStateToProps = (state, ownProps) => {
  const { post } = ownProps;
  const { posts, blockUserIds } = state.entities;
  const thisPost = posts[post.id] || post;
  if (!thisPost || blockUserIds.includes(thisPost.user.id)) {
    return { post: null };
  }
  return {
    post: {
      ...thisPost,
      comments: thisPost.comments.filter((c) => !blockUserIds.includes(c.user.id)),
    },
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  updatePost,
  likePost,
  unlikePost,
  submitComment,
  destroyComment,
  updateComment,
  likeComment,
  unlikeComment,
  favoritePost,
  unfavoritePost,
  createReport,
  blockUser,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PostViewContainer);
