import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'material-ui/Avatar';
import ActionFace from 'material-ui/svg-icons/action/face';
import { UserPropType } from 'common/constants/PropTypes';
import convertImgSrc from 'common/utils/convertImgSrc';

const styles = {
  defaultAvatar: {
    verticalAlign: 'text-bottom',
  },
};

class UserAvatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  render() {
    const { user, size, style } = this.props;
    if (!this.state.error && user.image && user.image.file_url) {
      const src = convertImgSrc(user.image.file_url, size, size, true);
      return (
        <Avatar
          src={src}
          size={size}
          style={style}
          onError={() => { this.setState({ error: true }); }}
        />
      );
    }
    return (
      <Avatar
        icon={<ActionFace />}
        size={size}
        style={Object.assign({}, styles.defaultAvatar, style)}
      />
    );
  }
}

UserAvatar.propTypes = {
  user: UserPropType.isRequired,
  size: PropTypes.number,
  style: PropTypes.shape(),
};

UserAvatar.defaultProps = {
  size: 40,
  style: {},
};

export default UserAvatar;
