import React from 'react';
import PropTypes from 'prop-types';
import PostsListContainer from '../containers/PostsListContainer';
import theme from '../../constants/theme';

const styles = {
  heading: {
    textAlign: 'center',
    color: theme.palette.primary1Color,
    fontSize: '1.2em',
    marginBottom: 8,
  },
};

const TagPage = props => (
  <div>
    <h2 style={styles.heading}>#{props.tag}</h2>
    <PostsListContainer
      paginationKey={`TAGS_${encodeURIComponent(props.tag)}`}
      searchParam={`tag=${encodeURIComponent(props.tag)}`}
    />
  </div>
);

TagPage.propTypes = {
  tag: PropTypes.string.isRequired,
};

export default TagPage;
