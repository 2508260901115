import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton';
import FontIcon from 'material-ui/FontIcon';
import Popover from 'material-ui/Popover/Popover';
import Tappable from 'react-tappable/lib/Tappable';
import { PostPropType, UserPropType } from 'common/constants/PropTypes';
import CloverTextIcon from '../utils/CloverTextIcon';
import theme from '../../constants/theme';

const styles = {
  tappable: {
    display: 'inline-block',
    width: 48,
    height: 48,
  },
  icon: {
    width: 24,
    height: 24,
    fontSize: 24,
  },
  button: {
    width: 48,
    height: 48,
    padding: 9,
    marginRight: 24,
  },
  popover: {
    paddingTop: 20,
    paddingRight: 30,
    paddingLeft: 30,
    paddingBottom: 30,
  },
  poppverIcon: {
    width: 36,
    height: 36,
    fontSize: 36,
  },
  popoverButton: {
    width: 72,
    height: 72,
  },
};

class LikeButton extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { popoverOpen: false };
    this.buttonEl = null;
    this.handleTouchTap = this.handleTouchTap.bind(this);
    this.handlePress = this.handlePress.bind(this);
  }

  isLiked() {
    const { post } = this.props;
    const { user } = this.context;
    return !!user && Object.keys(post.likes.users).includes(`${user.id}`);
  }

  handleTouchTap(kind = 'heart') {
    const { post, likePost, unlikePost, showDialogMessage } = this.props;
    const { user } = this.context;

    this.setState({ popoverOpen: false });

    if (!user) {
      if (showDialogMessage) {
        showDialogMessage('いいね！をするにはログインしてください。');
      }
      return;
    }

    if (this.isLiked()) {
      unlikePost(post, user);
      return;
    }

    likePost(post, user, kind);
  }

  handlePress() {
    const { user } = this.context;

    if (!user || this.isLiked()) {
      return;
    }

    if (!this.buttonEl || !this.buttonEl.button || !this.buttonEl.button.button) {
      return;
    }

    this.setState({
      popoverOpen: true,
      anchorEl: this.buttonEl.button.button,
    });
  }

  renderIcon() {
    if (!this.isLiked()) {
      return <FontIcon className="fa fa-heart-o" style={{ fontSize: 24 }} />;
    }
    const { post } = this.props;
    const { user } = this.context;
    const kind = post.likes.users[`${user.id}`];
    if (kind === 'clover') {
      return <CloverTextIcon />;
    }
    return <FontIcon className="fa fa-heart" color={theme.palette.primary1Color} />;
  }

  render() {
    return (
      <span>
        <Tappable
          onTap={() => this.handleTouchTap()}
          onPress={this.handlePress}
          style={styles.tappable}
          pressDelay={700}
        >
          <IconButton
            iconStyle={styles.icon}
            style={styles.button}
            ref={(r) => { this.buttonEl = r; }}
            disableTouchRipple
          >
            {this.renderIcon()}
          </IconButton>
        </Tappable>
        <Popover
          open={this.state.popoverOpen}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          onRequestClose={() => { this.setState({ popoverOpen: false }); }}
          style={styles.popover}
        >
          <div style={{ fontSize: 13, marginTop: 12, marginRight: 4, marginLeft: 4 }}>
            気持ちをアイコンで伝えましょう
          </div>
          <IconButton
            onClick={() => this.handleTouchTap()}
            iconStyle={styles.poppverIcon}
            style={styles.popoverButton}
          >
            <FontIcon className="fa fa-heart" style={{ fontSize: 36 }} color={theme.palette.primary1Color} />
          </IconButton>
          <IconButton
            onClick={() => this.handleTouchTap('clover')}
            iconStyle={styles.poppverIcon}
            style={styles.popoverButton}
          >
            <CloverTextIcon style={{ fontSize: 36 }} />
          </IconButton>
        </Popover>
      </span>
    );
  }
}

LikeButton.propTypes = {
  post: PostPropType.isRequired,
  likePost: PropTypes.func.isRequired,
  unlikePost: PropTypes.func.isRequired,
  showDialogMessage: PropTypes.func,
};

LikeButton.defaultProps = {
  showDialogMessage: null,
};

LikeButton.contextTypes = {
  user: UserPropType,
};

export default LikeButton;
