import React from 'react';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';
import Avatar from 'material-ui/Avatar';
import FlatButton from 'material-ui/FlatButton';
import ListItem from 'material-ui/List/ListItem';
import FontIcon from 'material-ui/FontIcon';
import '../../sass/articles.scss';

const styles = {
  container: {
    padding: 10,
    fontSize: 14,
  },
  heading: {
    marginTop: 5,
    marginBottom: 10,
  },
  authorContainer: {
    overflow: 'hidden',
  },
  socialButtons: {
    marginTop: 20,
  },
  twitter: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginRight: 10,
    marginBottom: 5,
  },
  facebook: {
    display: 'inline-block',
  },
  line: {
    verticalAlign: 'top',
    display: 'inline-block',
    marginRight: 10,
  },
  author: {
    float: 'right',
  },
  button: {
    marginTop: 10,
  },
};

/* eslint-disable react/no-danger */
const ArticlePage = props => (
  <div>
    <Paper style={styles.container}>
      <h2 style={styles.heading}>{props.article.title}</h2>
      <div className="articleContent" dangerouslySetInnerHTML={{ __html: props.article.content }} />
      <ul style={styles.socialButtons}>
        <li style={styles.twitter}>
          <a
            href="https://twitter.com/share"
            className="twitter-share-button"
            data-text={props.article.title}
            data-lang="ja"
          >
            ツイート
          </a>
        </li>
        <li style={styles.facebook}>
          <div
            className="fb-like"
            data-send="true"
            data-layout="button_count"
            data-show-faces="true"
          />
        </li>
      </ul>
      <div style={styles.authorContainer}>
        <ListItem
          style={styles.author}
          disabled
          leftAvatar={
            <Avatar src={props.article.author.avatar_urls[96]} />
          }
        >
          {props.article.author.name}
        </ListItem>
      </div>
    </Paper>
    <FlatButton
      icon={<FontIcon className="fa fa-caret-left" />}
      style={styles.button}
      label="記事一覧へ"
      href="/articles"
    />
  </div>
);
/* eslint-enable */

ArticlePage.propTypes = {
  article: PropTypes.shape().isRequired,
};

export default ArticlePage;
