import React from 'react';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import { red500 } from 'material-ui/styles/colors';

const styles = {
  container: {
    padding: 10,
  },
  additional: {
    marginTop: 10,
    textAlign: 'center',
  },
  button: {
    marginTop: 10,
    marginBottom: 10,
  },
  error: {
    paddingBottom: 10,
    color: red500,
  },
};

function renderErrors(errors) {
  if (!errors) {
    return null;
  }

  return (
    <div>
      {errors.map(e => (
        <div key={e} style={styles.error}>{e}</div>
      ))}
    </div>
  );
}

const EditPassword = (props) => {
  const { authenticityToken, errors } = props;
  return (
    <div>
      {renderErrors(errors)}
      <Paper style={styles.container}>
        <h2>パスワード再設定</h2>
        <form action="/users/password" method="post">
          <input type="hidden" name="_method" value="put" />
          <input type="hidden" name="authenticity_token" value={authenticityToken} />
          <input type="hidden" name="user[reset_password_token]" value={props.reset_password_token} />
          <TextField
            hintText="新しいパスワードを入力してください"
            floatingLabelText="パスワード"
            name="user[password]"
            type="password"
            fullWidth
            required
          />
          <TextField
            hintText="新しいパスワード（確認）を入力してください"
            floatingLabelText="パスワード（確認）"
            name="user[password_confirmation]"
            type="password"
            fullWidth
            required
          />
          <RaisedButton
            style={styles.button}
            label="パスワード再設定"
            type="submit"
            primary
            fullWidth
          />
        </form>
      </Paper>
      <div style={styles.additional}><a href="/users/sign_in">ログインはこちら</a></div>
      <div style={styles.additional}><a href="/users/sign_up">まだアカウントをお持ちでない方はこちら</a></div>
    </div>
  );
};

EditPassword.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  reset_password_token: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
};

EditPassword.defaultProps = {
  errors: [],
};

export default EditPassword;
