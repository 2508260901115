import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardText, CardActions } from 'material-ui/Card';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import Chip from 'material-ui/Chip';
import FontIcon from 'material-ui/FontIcon';
import IconButton from 'material-ui/IconButton';
import Paper from 'material-ui/Paper';
import NavigationMoreVert from 'material-ui/svg-icons/navigation/more-vert';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import Overlay from 'material-ui/internal/Overlay';
import {
  grey500, blue200, lightGreen300, amber300, pinkA100, deepOrange200, darkBlack,
} from 'material-ui/styles/colors';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { Row, Col } from 'react-flexbox-grid';

import Img from 'common/components/Img';
import ImpressionTag from 'common/components/ImpressionTag';
import { PostPropType, UserPropType, AdPropType } from 'common/constants/PropTypes';
import trackEvent from 'common/utils/trackEvent';
import UserAvatar from '../utils/UserAvatar';
import LikeButton from './LikeButton';
import FavoriteButton from './FavoriteButton';
import LikersButton from './LikersButton';
import CommentList from './CommentList';
import Rectangle from '../utils/Rectangle';
import {
  DIARY, QUESTION, EVENT, RECOMMEND,
} from '../../constants/PostTypes';
import displayDateTime from '../../utils/displayDateTime';
import escapeHtml from '../../utils/escapeHtml';
import scrollTo from '../../utils/scrollTo';
import EditDialogContainer from '../containers/EditDialogContainer';

const styles = {
  userLink: {
    textDecoration: 'none',
  },
  userLinkLogin: {
    fontSize: 'small',
    color: grey500,
    marginLeft: 5,
  },
  timeLink: {
    textDecoration: 'none',
    color: grey500,
  },
  chipWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 4,
    cursor: 'pointer',
  },
  img: {
    minWidth: '100%',
    minHeight: '100%',
    width: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    WebkitTransform: 'translate3d(-50%, -50%, 0)',
    cursor: 'pointer',
  },
  carouselDialog: {
    width: '100%',
    maxWidth: 'none',
  },
  carouselDialogBody: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 0,
    paddingRight: 0,
  },
  carouselDialogOverlay: {
    backgroundColor: darkBlack,
  },
};

const renderPostTypeChip = (postType) => {
  switch (postType) {
    case DIARY:
      return (
        <Chip
          style={styles.chip}
          backgroundColor={blue200}
          href="/diaries"
        >
          ダイアリー
        </Chip>
      );
    case QUESTION:
      return (
        <Chip
          style={styles.chip}
          backgroundColor={lightGreen300}
          href="/questions"
        >
          質問
        </Chip>
      );
    case EVENT:
      return (
        <Chip
          style={styles.chip}
          backgroundColor={amber300}
          href="/events"
        >
          イベント
        </Chip>
      );
    case RECOMMEND:
      return (
        <Chip
          style={styles.chip}
          backgroundColor={deepOrange200}
          href="/recommends"
        >
          使い方
        </Chip>
      );
    default:
      break;
  }
  return null;
};

const renderCategoryChip = (category) => {
  if (!category) {
    return null;
  }
  return (
    <Chip
      style={styles.chip}
      href={`/tags/${encodeURIComponent(category)}`}
    >
      {category}
    </Chip>
  );
};

class PostView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogMessage: null,
      cardPopoverOpen: false,
      cardNavButtonEl: null,
      destroyPostDialog: false,
      editPostDialog: false,
      reportPostDialog: false,
      blockUserDialog: false,
      commentFocused: false,
      audience: props.post.audience,
      changeAudienceDialog: false,
      contentExpand: props.contentExpand,
    };
    this.cardNode = null;
    this.showDialogMessage = this.showDialogMessage.bind(this);
    this.handleTouchCardNavi = this.handleTouchCardNavi.bind(this);
    this.handleCardPopoverRequestClose = this.handleCardPopoverRequestClose.bind(this);
    this.handleUpdatePost = this.handleUpdatePost.bind(this);
    this.handleReportPost = this.handleReportPost.bind(this);
    this.handleBlockUser = this.handleBlockUser.bind(this);
    this.handleCommentFocus = this.handleCommentFocus.bind(this);
  }

  componentDidMount() {
    this.scrollToImage();
  }

  componentDidUpdate() {
    this.scrollToImage();
  }

  scrollToImage() {
    if (!this.props.showAllImages) {
      return;
    }

    const hash = location.hash.match(/^#(image\d+)$/);
    if (!hash) {
      return;
    }
    if ('replaceState' in history) {
      // URLからハッシュをなくす
      history.replaceState('', document.title, location.pathname + location.search);
    }
    this.scrollToElement(hash[1]);
  }

  scrollToElement(elId) {
    if (document.getElementById(elId)) {
      scrollTo(elId);
      return;
    }
    setTimeout(() => this.scrollToElement(), 100);
  }

  handleTouchCardNavi(event) {
    event.preventDefault();
    this.setState({
      cardPopoverOpen: true,
      cardNavButtonEl: event.currentTarget,
    });
  }

  handleCardPopoverRequestClose() {
    this.setState({
      cardPopoverOpen: false,
    });
  }

  handleUpdatePost() {
    this.setState({ editPostDialog: false, changeAudienceDialog: false });
    const { audience } = this.state;
    const { user } = this.context;
    const post = Object.assign({}, this.props.post, { audience });
    this.props.updatePost(post, user);
  }

  handleReportPost() {
    const { post, createReport } = this.props;
    this.setState({ reportPostDialog: false });
    const { user } = this.context;
    createReport('Post', post.id, user);
  }

  handleBlockUser() {
    const { post, blockUser } = this.props;
    this.setState({ blockUserDialog: false });
    const { user } = this.context;
    blockUser(post.user.id, user);
  }

  handleCommentFocus(commentFocused) {
    this.setState({ commentFocused });
  }

  showDialogMessage(message) {
    this.setState({ dialogMessage: message });
  }

  renderContent() {
    const { post, ad } = this.props;
    const urlRegexp = /\[\[(([^[\]]+)&gt;)?([^[\]]+)\]\]/g;
    const isTruncate = !this.state.contentExpand && post.content.length > 149;
    const postContent = isTruncate ?
      // truncateした後でURLリンクの途中のものは消しておく
      post.content.substr(0, 125).replace(/\[\[[^[\]]+[^\]]$/, () => '') :
      post.content;
    // mentionリンク
    const contentMentions = escapeHtml(postContent)
      .replace(/[@＠]([a-zA-Z0-9_]{4,12})/g, (m, l) => `<a href="/users/${l}">${m}</a>`);
    // urlリンク[[テキスト>URL]]
    const contentUrls = contentMentions.replace(urlRegexp, (m, p1, p2, p3) => {
      const text = p2 || p3;
      if (p3.startsWith('http') && !p3.startsWith(location.origin)) {
        return `<a href="${p3}" target="_blank">${text}` +
          '<i class="fa fa-external-link" style="font-size:12px;margin-left:1px;"></i>' +
          '</a>';
      }
      return `<a href="${p3}">${text}</a>`;
    });
    // ハッシュタグリンク
    const content = post.tag_list.reduce((p, c) => {
      const re = new RegExp(String.raw`[#＃]${c}`, 'gi');
      const replacer = `<a href="/tags/${c}">#${c}</a>`;
      return p.replace(re, replacer);
    }, contentUrls);
    const more = isTruncate ? (
      <span>
        <span>... </span>
        <span // eslint-disable-line jsx-a11y/click-events-have-key-events
          className="hover-underline"
          style={{ color: '#365899' }}
          onClick={() => { this.setState({ contentExpand: true }); }}
        >
          もっと見る
        </span>
      </span>
    ) : null;

    /* eslint-disable react/no-danger */
    return (
      <div>
        <span
          dangerouslySetInnerHTML={{
            __html: content.replace(/\n/g, '<br />'),
          }}
        />
        {more}
        <ImpressionTag ad={ad} />
      </div>
    );
    /* eslint-enable */
  }

  renderImages() {
    const { post, showAllImages, ad } = this.props;
    if (showAllImages) {
      return null;
    }
    const postAd = ad || ((post.ads && post.ads.length > 0) ? post.ads[0] : null);

    const imgElement = (fileUrl) => {
      if (postAd) {
        if (postAd.url && postAd.url.length > 0) {
          return (
            <a
              href={postAd.url}
              title={postAd.link_title}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => trackEvent('ad', 'click', postAd.id)}
            >
              <Img
                src={fileUrl}
                style={styles.img}
                alt=""
              />
            </a>
          );
        }
        return (
          <Img
            src={fileUrl}
            style={styles.img}
            alt=""
          />
        );
      }
      return (
        <a href={`/posts/${post.id}#image0`}>
          <Img
            src={fileUrl}
            style={styles.img}
            alt=""
          />
        </a>
      );
    };

    let adLink = null;
    if (postAd && postAd.url && postAd.url.length > 0) {
      adLink = (
        <p style={{ fontSize: 'large', textAlign: 'center' }}>
          <a
            href={postAd.url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => trackEvent('ad', 'click', postAd.id)}
          >
            {postAd.link_title}
          </a>
        </p>
      );
    }

    const { images } = post;
    switch (images.length) {
      case 0:
        return null;
      case 1:
        // 横長はそのまま、縦長は4:3で表示
        if (images[0].width > images[0].height * 1.33) {
          return (
            <div>
              <Rectangle aspectRatio={images[0].width / images[0].height || 1.33}>
                <div style={{ marginTop: 5 }}>
                  {imgElement(images[0].file_url)}
                </div>
              </Rectangle>
              <div>{adLink}</div>
            </div>
          );
        }
        return (
          <div style={{ marginTop: 5 }}>
            <Rectangle aspectRatio={1.33}>
              {imgElement(images[0].file_url)}
            </Rectangle>
            <div>{adLink}</div>
          </div>
        );
      case 2:
        return (
          <div style={{ marginTop: 5 }}>
            <Rectangle aspectRatio={1.91} style={{ marginBottom: 2 }}>
              {imgElement(images[0].file_url)}
            </Rectangle>
            <Rectangle aspectRatio={1.91}>
              {imgElement(images[1].file_url)}
            </Rectangle>
            <div>{adLink}</div>
          </div>
        );
      default:
        break;
    }

    const moreImages = images.length === 3 ?
      null :
      <p style={{ fontSize: 'large', textAlign: 'right' }}>あと{images.length - 3}枚の画像があります。</p>;

    return (
      <div style={{ marginTop: 5 }}>
        <Row>
          <Col xs={12} style={{ paddingBottom: 2 }}>
            <Rectangle aspectRatio={1.91}>
              <a href={`/posts/${post.id}#image0`}>
                <Img
                  src={images[0].file_url}
                  style={styles.img}
                  alt=""
                />
              </a>
            </Rectangle>
          </Col>
          <Col xs={6} style={{ paddingRight: 2 }}>
            <Rectangle aspectRatio={1}>
              <a href={`/posts/${post.id}#image1`}>
                <Img
                  src={images[1].file_url}
                  style={styles.img}
                  alt=""
                />
              </a>
            </Rectangle>
          </Col>
          <Col xs={6} style={{ paddingLeft: 2 }}>
            <Rectangle aspectRatio={1}>
              <a href={`/posts/${post.id}#image2`}>
                <Img
                  src={images[2].file_url}
                  style={styles.img}
                  alt=""
                />
              </a>
            </Rectangle>
          </Col>
        </Row>
        <div>{moreImages}</div>
        <div>{adLink}</div>
      </div>
    );
  }

  renderAllImages() {
    const { showAllImages, post } = this.props;
    if (!showAllImages) {
      return null;
    }

    const { images } = post;
    if (!images || images.length === 0) {
      return null;
    }

    return images.map((image, i) => (
      <Paper
        id={`image${i}`}
        style={{ marginTop: 5, marginBottom: 5 }}
        key={image.file_url}
      >
        <Img
          src={image.file_url}
          style={{ width: '100%', marginBottom: -3 }}
          alt=""
        />
      </Paper>
    ));
  }

  renderCommentList() {
    const {
      post,
      showComments,
      submitComment,
      destroyComment,
      updateComment,
      likeComment,
      unlikeComment,
      createReport,
      blockUser,
    } = this.props;

    if (!showComments) {
      return null;
    }
    return (
      <CardText>
        <CommentList
          post={post}
          submitComment={submitComment}
          destroyComment={destroyComment}
          updateComment={updateComment}
          ref={(c) => { this.commentList = c; }}
          handleCommentFocus={this.handleCommentFocus}
          likeComment={likeComment}
          unlikeComment={unlikeComment}
          showDialogMessage={this.showDialogMessage}
          createReport={createReport}
          blockUser={blockUser}
        />
      </CardText>
    );
  }

  renderCardActions() {
    const { post, likePost, unlikePost, favoritePost, unfavoritePost, ad } = this.props;
    const isAd = !!ad || (post.ads && post.ads.length > 0);
    const { user } = this.context;

    const href = location.pathname === `/posts/${post.id}` ?
      null :
      `/posts/${post.id}#comments`;
    const onClick = href ?
      () => {} :
      () => {
        if (user) {
          if (this.commentList) {
            scrollTo(`post${post.id}CommentInput`);
          }
        } else {
          this.showDialogMessage('コメントするにはログインしてください');
        }
      };

    const buttons = ([
      <LikeButton
        key={1}
        post={post}
        likePost={likePost}
        unlikePost={unlikePost}
        showDialogMessage={this.showDialogMessage}
      />,
    ]);
    if (!isAd) {
      const commentLabel = post.type === QUESTION ? '回答' : 'コメント';
      buttons.push((
        <FlatButton
          key={3}
          href={href}
          onClick={onClick}
        >
          <div style={{ height: 36 }}>
            <span>
              <FontIcon style={{ fontSize: 18 }} className="fa fa-commenting-o" />
              <small style={{ paddingLeft: 3 }}>
                {post.comments.length > 0 ? `${post.comments.length}件` : null}
              </small>
            </span>
            <span style={{ fontSize: 16 }}>{commentLabel}</span>
          </div>
        </FlatButton>
      ));
    }
    if (user) {
      buttons.push((
        <FavoriteButton
          key={5}
          post={post}
          favoritePost={favoritePost}
          unfavoritePost={unfavoritePost}
        />
      ));
    }
    return buttons;
  }

  renderPopover() {
    const { post } = this.props;
    const { user } = this.context;
    if (!user) {
      return null;
    }
    if (user.id !== post.user.id) {
      return this.renderPopoverOthersPost();
    }
    return this.renderPopOverMyPost();
  }

  renderPopOverMyPost() {
    const { post } = this.props;
    const { user } = this.context;
    return (
      <div>
        <Popover
          open={this.state.cardPopoverOpen}
          anchorEl={this.state.cardNavButtonEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          onRequestClose={this.handleCardPopoverRequestClose}
        >
          <Menu>
            <MenuItem
              primaryText="編集する..."
              onClick={() => {
                this.handleCardPopoverRequestClose();
                this.setState({ editPostDialog: true });
              }}
            />
            <MenuItem
              primaryText="削除する..."
              onClick={() => {
                this.handleCardPopoverRequestClose();
                this.setState({ destroyPostDialog: true });
              }}
            />
            <MenuItem
              primaryText="公開範囲を変更する..."
              onClick={() => {
                this.handleCardPopoverRequestClose();
                this.setState({ changeAudienceDialog: true });
              }}
            />
          </Menu>
        </Popover>
        <Dialog
          actions={[
            <FlatButton
              label="削除"
              href={`/posts/${post.id}`}
              data-method="delete"
              onClick={() => { this.setState({ destroyPostDialog: false }); }}
            />,
            <FlatButton
              label="キャンセル"
              keyboardFocused
              primary
              onClick={() => { this.setState({ destroyPostDialog: false }); }}
            />,
          ]}
          open={this.state.destroyPostDialog}
          onRequestClose={() => { this.setState({ destroyPostDialog: false }); }}
        >
          本当に削除しますか？この操作は取り消せません。
        </Dialog>
        <EditDialogContainer
          user={user}
          open={this.state.editPostDialog}
          onRequestClose={() => { this.setState({ editPostDialog: false }); }}
          post={post}
        />
        <Dialog
          actions={[
            <FlatButton
              label="キャンセル"
              onClick={() => {
                this.setState({ changeAudienceDialog: false, audience: post.audience });
              }}
            />,
            <FlatButton
              label="更新"
              primary
              onClick={this.handleUpdatePost}
            />,
          ]}
          open={this.state.changeAudienceDialog}
          onRequestClose={() => { this.setState({ changeAudienceDialog: false }); }}
        >
          <RadioButtonGroup
            defaultSelected={this.state.audience}
            onChange={(e, v) => { this.setState({ audience: v }); }}
            name="post[audience]"
          >
            <RadioButton
              value="public"
              label="公開"
            />
            <RadioButton
              value="members"
              label="限定公開"
            />
          </RadioButtonGroup>
        </Dialog>
      </div>
    );
  }

  renderPopoverOthersPost() {
    return (
      <div>
        <Popover
          open={this.state.cardPopoverOpen}
          anchorEl={this.state.cardNavButtonEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          onRequestClose={this.handleCardPopoverRequestClose}
        >
          <Menu>
            <MenuItem
              primaryText="不適切な投稿として通報する..."
              onClick={() => {
                this.handleCardPopoverRequestClose();
                this.setState({ reportPostDialog: true });
              }}
            />
            <MenuItem
              primaryText="このユーザをブロックする..."
              onClick={() => {
                this.handleCardPopoverRequestClose();
                this.setState({ blockUserDialog: true });
              }}
            />
          </Menu>
        </Popover>
        <Dialog
          actions={[
            <FlatButton
              label="キャンセル"
              keyboardFocused
              onClick={() => { this.setState({ reportPostDialog: false }); }}
            />,
            <FlatButton
              label="通報"
              primary
              onClick={this.handleReportPost}
            />,
          ]}
          open={this.state.reportPostDialog}
          onRequestClose={() => { this.setState({ reportPostDialog: false }); }}
        >
          不適切な投稿として通報しますか？
        </Dialog>
        <Dialog
          actions={[
            <FlatButton
              label="キャンセル"
              keyboardFocused
              onClick={() => { this.setState({ blockUserDialog: false }); }}
            />,
            <FlatButton
              label="ブロック"
              primary
              onClick={this.handleBlockUser}
            />,
          ]}
          open={this.state.blockUserDialog}
          onRequestClose={() => { this.setState({ blockUserDialog: false }); }}
          title={`${this.props.post.user.name}をブロックしますか？`}
        >
          ブロックしたことは相手には知られません。
        </Dialog>
      </div>
    );
  }

  renderCard() {
    const { post, ad } = this.props;
    const postAd = ad || ((post.ads && post.ads.length > 0) ? post.ads[0] : null);

    const { user } = this.context;
    let limitedLabel = null;
    if (user && user.id === post.user.id && post.audience === 'members') {
      limitedLabel = (
        <div style={{ fontSize: 'small', color: pinkA100 }}>
          会員限定
        </div>
      );
    }

    const naviButton = user ? (
      <div
        style={{ position: 'absolute', top: 0, bottom: 0, right: 4, margin: 'auto' }}
      >
        <IconButton
          onClick={this.handleTouchCardNavi}
        >
          <NavigationMoreVert />
        </IconButton>
        {limitedLabel}
      </div>
    ) : null;

    const title = (
      <a style={styles.userLink} href={`/users/${post.user.login}`}>
        {post.user.name}
        <wbr />
        <span style={styles.userLinkLogin}>@{post.user.login || '????'}</span>
      </a>
    );

    let subtitle = null;
    if (postAd) {
      if (postAd.url && postAd.url.length > 0) {
        subtitle = (
          <a
            style={styles.timeLink}
            title="詳しくはこちら"
            href={postAd.url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => trackEvent('ad', 'click', postAd.id)}
          >
            広告
          </a>
        );
      } else {
        subtitle = <span>広告</span>;
      }
    } else {
      subtitle = (
        <a style={styles.timeLink} href={`/posts/${post.id}`} title={displayDateTime(post.created_at, false)}>
          {displayDateTime(post.created_at)}
        </a>
      );
    }

    return (
      <Card style={{ overflowX: 'hidden' }}>
        <CardHeader
          style={{ paddingBottom: 0 }}
          title={title}
          subtitle={subtitle}
          avatar={<a style={styles.userLink} href={`/users/${post.user.login}`}><UserAvatar user={post.user} /></a>}
        >
          {naviButton}
        </CardHeader>
        <CardText style={{ paddingTop: 0, paddingBottom: 0 }}>
          <div style={styles.chipWrapper}>
            {renderPostTypeChip(post.type)}
            {renderCategoryChip(post.category)}
          </div>
        </CardText>
        <CardText style={{ paddingBottom: 0 }}>
          {this.renderContent()}
          {this.renderImages()}
        </CardText>
        <CardActions>
          <div className="clearfix">
            {this.renderCardActions()}
          </div>
          <LikersButton post={post} />
        </CardActions>
        {this.renderCommentList()}
      </Card>
    );
  }

  render() {
    const { post, muiTheme } = this.props;
    if (!post) {
      return null;
    }

    const { commentFocused } = this.state;

    const height = (this.cardNode) ? this.cardNode.clientHeight : document.body.clientHeight - 137;
    const containerWrapperStyles = commentFocused ?
      { position: 'relative', width: '100%', height } :
      null;
    const containerStyles = commentFocused ?
      { position: 'absolute', zIndex: muiTheme.zIndex.dialogOverlay + 1, width: '100%' } :
      null;

    return (
      <div style={containerWrapperStyles}>
        <div style={containerStyles} ref={(d) => { this.cardNode = d; }}>
          {this.renderCard()}
        </div>
        {this.renderAllImages()}
        {this.renderPopover()}
        <Dialog
          actions={(
            <FlatButton
              label="OK"
              onClick={() => { this.setState({ dialogMessage: null }); }}
            />
          )}
          open={!!this.state.dialogMessage && this.state.dialogMessage.length > 0}
          onRequestClose={() => { this.setState({ dialogMessage: null }); }}
        >
          {this.state.dialogMessage}
        </Dialog>
        <Overlay
          show={commentFocused}
          style={{ zIndex: muiTheme.zIndex.dialogOverlay }}
        />
      </div>
    );
  }
}

PostView.propTypes = {
  post: PostPropType.isRequired,
  muiTheme: PropTypes.shape().isRequired,
  updatePost: PropTypes.func.isRequired,
  likePost: PropTypes.func.isRequired,
  unlikePost: PropTypes.func.isRequired,
  createReport: PropTypes.func.isRequired,
  blockUser: PropTypes.func.isRequired,
  submitComment: PropTypes.func.isRequired,
  destroyComment: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  showComments: PropTypes.bool,
  showAllImages: PropTypes.bool,
  contentExpand: PropTypes.bool,
  likeComment: PropTypes.func.isRequired,
  unlikeComment: PropTypes.func.isRequired,
  favoritePost: PropTypes.func.isRequired,
  unfavoritePost: PropTypes.func.isRequired,
  ad: AdPropType,
};

PostView.defaultProps = {
  showComments: false,
  showAllImages: false,
  contentExpand: false,
  ad: null,
};

PostView.contextTypes = {
  user: UserPropType,
};

export default muiThemeable()(PostView);
