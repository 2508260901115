import React from 'react';
import ClearFix from 'material-ui/internal/ClearFix';
import Divider from 'material-ui/Divider';
import { grey500 } from 'material-ui/styles/colors';

const styles = {
  container: {
    marginTop: 20,
    fontSize: 13,
    color: grey500,
  },
  content: {
    width: 540,
    maxWidth: '100%',
    margin: '10px auto',
    paddingLeft: 10,
    paddingRight: 10,
  },
  li: {
    float: 'left',
    listStyle: 'none',
    marginRight: 8,
  },
  link: {
    color: grey500,
  },
};

const UserFooter = () => (
  <div style={styles.container}>
    <Divider />
    <div style={styles.content}>
      <ClearFix>
        <ul>
          <li style={styles.li}>
            <a
              className="hover-underline"
              href="https://risa-sana.co/"
              target="_company"
              style={styles.link}
            >
              運営会社
            </a>
          </li>
          <li style={styles.li}>
            <a className="hover-underline" href="/terms" style={styles.link}>
              利用規約
            </a>
          </li>
          <li style={styles.li}>
            <a className="hover-underline" href="/privacy" style={styles.link}>
              プライバシーポリシー
            </a>
          </li>
          <li style={styles.li}>
            <a className="hover-underline" href="/guideline" style={styles.link}>
              利用に関する注意点
            </a>
          </li>
          <li style={styles.li}>
            <a className="hover-underline" href="/inquiries" style={styles.link}>
              お問い合わせ
            </a>
          </li>
        </ul>
      </ClearFix>
      <div>&copy; Risa Sana, Inc</div>
    </div>
  </div>
);

export default UserFooter;
