import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'material-ui/Avatar';
import Paper from 'material-ui/Paper';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import ClearFix from 'material-ui/internal/ClearFix';
import NotificationSms from 'material-ui/svg-icons/notification/sms';
import { CategoryPropType } from 'common/constants/PropTypes';
import PostsListContainer from '../containers/PostsListContainer';
import {
  EVENT, QUESTION, DIARY, RECOMMEND, FAVORITE,
} from '../../constants/PostTypes';
import theme from '../../constants/theme';

const styles = {
  hintComment: {
    padding: 16,
    fontSize: 14,
  },
  dropDownContainer: {
    marginTop: -5,
    marginBottom: 6,
  },
  dropDownLabel: {
    float: 'right',
    paddingTop: 20,
    fontSize: 14,
  },
  dropDownMenu: {
    float: 'right',
  },
};

const postTypes = {
  events: EVENT,
  questions: QUESTION,
  diaries: DIARY,
  recommends: RECOMMEND,
  favorites: FAVORITE,
};

const getSearchParam = (display, tag) => {
  const param = `type=${postTypes[display]}`;
  if (tag) {
    return `${param}&tag=${encodeURIComponent(tag)}`;
  }
  return param;
};

const getPaginationKey = (display, tag) => {
  const paginationKey = display.toUpperCase();
  if (tag) {
    return `${paginationKey}?tag=${tag}`;
  }
  return paginationKey;
};

const renderHintComment = (display, categories) => {
  if (display !== 'questions' && display !== 'diaries') {
    return null;
  }

  const categoryMenus = categories.map(c => (
    <MenuItem
      key={c.id}
      value={`?tag=${encodeURIComponent(c.name)}`}
      primaryText={c.name}
    />
  ));

  return (
    <div>
      {display === 'questions' && (
        <Paper style={styles.hintComment}>
          <div style={{ display: 'flex' }}>
            <div style={{ paddingRight: 16 }}>
              <Avatar
                icon={<NotificationSms />}
                backgroundColor={theme.palette.accent1Color}
              />
            </div>
            <div>
              <div style={{ height: 40, display: 'table-cell', verticalAlign: 'middle' }}>
                似た質問があっても、大丈夫。気軽に仲間に聞いてみましょう！
              </div>
            </div>
          </div>
        </Paper>
      )}
      <ClearFix style={styles.dropDownContainer}>
        <DropDownMenu
          style={styles.dropDownMenu}
          labelStyle={{ paddingRight: 48, paddingLeft: 12 }}
          iconStyle={{ right: 0 }}
          underlineStyle={{ margin: '-1px 12px' }}
          value={location.search}
          onChange={(e, i, v) => { global.Turbolinks.visit(`/${display}${v}`); }}
        >
          <MenuItem value="" primaryText="すべて" />
          {categoryMenus}
        </DropDownMenu>
        <span style={styles.dropDownLabel}>病名:</span>
      </ClearFix>
    </div>
  );
};

const PostListPage = (props, context) => (
  <div>
    {renderHintComment(props.display, context.categories)}
    <PostsListContainer
      paginationKey={getPaginationKey(props.display, props.tag)}
      searchParam={getSearchParam(props.display, props.tag)}
    />
  </div>
);

PostListPage.propTypes = {
  display: PropTypes.oneOf(['events', 'questions', 'diaries', 'favorites']).isRequired,
  tag: PropTypes.string,
};

PostListPage.defaultProps = {
  tag: null,
};

PostListPage.contextTypes = {
  categories: PropTypes.arrayOf(CategoryPropType).isRequired,
};

export default PostListPage;
